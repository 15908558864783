<header></header>

<menu></menu>

<div class="content">
  <div class="content_container" *ngIf="betRound">
    <div class="info_container left_container">
      <span class="headline first_headline">Daten ändern</span>
      <span class="info">Hier kannst du deinen Namen und wichtige Einstellungen ändern. Diese werden nach dem Speichern aktiv.</span>
      <div class="standard_input_text">
        <label class="input_text_label edit_in_place">Name</label>
        <input id="name" type="text" class="edit_in_place" [(ngModel)]="betRound.name"
               [ngModelOptions]="{standalone: true}" required [disabled]="!betRound.admin"/>
      </div>
      <div class="standard_input_text">
        <label class="input_text_label edit_in_place">Beschreibung</label>
        <textarea id="description" class="edit_in_place" [(ngModel)]="betRound.description"
                  [ngModelOptions]="{standalone: true}" required [disabled]="!betRound.admin"></textarea>
      </div>

      <span class="info">Es gibt drei verschiedene Arten von der Punkteberechnung. Richtig - [Differenz] - Tendenz - Falsch. Das U steht für Unentschieden.</span>
      <div class="uirepo_radiobox uirepo_radiobox_big">
        <label>
          <input type="radio" name="point_calculation" [value]="1"
                 [(ngModel)]="betRound.calculationType" [disabled]="!betRound.admin"/>
          <i class="radiobox_helper"></i>
          <span>3-1-0</span>
        </label>
      </div>
      <div class="uirepo_radiobox uirepo_radiobox_big">
        <label>
          <input type="radio" name="point_calculation" [value]="2"
                 [(ngModel)]="betRound.calculationType" [disabled]="!betRound.admin"/>
          <i class="radiobox_helper"></i>
          <span>3-2-1(U)-0</span>
        </label>
      </div>
      <div class="uirepo_radiobox uirepo_radiobox_big">
        <label>
          <input type="radio" name="point_calculation" [value]="3"
                 [(ngModel)]="betRound.calculationType" [disabled]="!betRound.admin"/>
          <i class="radiobox_helper"></i>
          <span>3-2(U)-1-0</span>
        </label>
      </div>

      <div>
        <input type="button" value="Speichern" (click)="saveBetRound()" *ngIf="betRound.admin"/>
      </div>

      <span class="headline">Bild ändern</span>
      <span class="info">Hier kannst du dein Bild ändern. Dieses wird dann direkt überall geändert.</span>
      <img-cropper #cropper [hidden]="!cropperActive" [image]="croppedImageData" [settings]="cropperSettings"
                   (onCrop)="cropped($event)"></img-cropper>
      <div class="image_cropper_buttons">
        <input type="button" value="Abbrechen" class="reset_button"
               *ngIf="cropperActive" (click)="closeCropper()"/>
        <input type="button" value="Speichern"
               *ngIf="cropperActive" (click)="upload()"/>
      </div>
      <div class="main_img" *ngIf="!cropperActive">
        <img class="favorite_img" alt="Tipprundenbild"
             src="https://www.tippfreun.de/LoadBetRoundPhotoServlet?scaleSize=150&amp;betRoundId={{betRound?.id}}&amp;randomImageId={{sharedService.randomImageId}}"
             *ngIf="betRound.hasPhoto && !cropperActive"/>
        <span class="no_favorite_img" *ngIf="!betRound.hasPhoto && !cropperActive">Noch kein Bild vorhanden</span>

        <div class="new_img" *ngIf="betRound.admin">
          <span class="add_image">Bild auswählen und hochladen</span>
          <input type="file" name="file_upload" (change)="fileUpload($event)" class="upload"
                 accept=".jpg,.jpeg,.png">
        </div>
      </div>

      <span class="headline">Wettbewerbe und Bonustipps <span class="league_warning"
                                                              *ngIf="betRound.leagues.length == 0">Füge eine Liga hinzu um in der Tipprunden tippen zu können.</span></span>
      <div *ngFor="let league of betRound.leagues" class="table">
        <span class="table_cell_100">
          {{league.name}} {{league.saison}}
          <span class="subtitle subtitle_red" *ngIf="league.expired">archiviert</span>
          <span class="subtitle subtitle_green" *ngIf="!league.expired">aktiv</span>
        </span>
        <span class="table_cell_menu" *ngIf="betRound.admin">
            <a class="menu_link" (click)="sharedJqueryService.openCloseMenu('league'+ league.id);">
              <img class="more_menu" src="assets/matchday/more_menu.png" alt="Menü"/>
              <img class="more_menu" src="assets/matchday/more_menu_hover.png" alt="Menü"/>
            </a>
            <div id="menu_league{{league.id}}" class="menu">
              <a class="menu_entry" (click)="loadBonusQuestionsForLeague(league)"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/betround/edit.png" alt="Bearbeiten"/>
								</span> <span class="menu_entry_text"> BONUSTIPPS </span>
              </a>
              <a class="menu_entry" (click)="openDeleteLeagueOverlay(league)"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/betround/delete.png" alt="Löschen"/>
								</span> <span class="menu_entry_text"> LÖSCHEN </span>
              </a>
            </div>
        </span>
      </div>
      <div style="margin-top: 5px;">
        <input type="button" value="Neue Liga hinzufügen" (click)="loadAvailableLeagues()" *ngIf="betRound.admin"/>
      </div>
    </div>

    <div class="info_container right_container">

      <span *ngIf="betRound.completeBetRoundUrlAccessLink && betRound.admin" class="headline first_headline">Einladungs-Link</span>
      <span *ngIf="betRound.completeBetRoundUrlAccessLink && betRound.admin" class="info">Den Link kannst Du an beliebige Spieler weitergeben. Über diesen können Deine Freunde Deiner Tipprunde beitreten. Anklicken zum Kopieren.</span>

      <textarea *ngIf="betRound.completeBetRoundUrlAccessLink && betRound.admin" id="betroundLink" class="url_access_token" readonly
                (click)="copyLink()">{{betRound.completeBetRoundUrlAccessLink}}</textarea>

      <span class="headline">Mitglieder</span>
      <span class="info">Bearbeiten der Mitglieder und Einladungen dieser Tipprunde. Tipprunden-Eigentümer sind blau geschrieben.</span>
      <div *ngFor="let betRoundUser of betRound.betRoundUsers" class="table">
        <span class="table_cell_img">
          <img style="border-radius: 25px; vertical-align: bottom" alt="Nutzerbild"
               src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=30&amp;userId={{betRoundUser?.user?.id}}&amp;randomImageId={{sharedService.randomImageId}}"/>
        </span>
        <span [class]="betRoundUser.admin ? 'table_cell_100 admin' : 'table_cell_100'">
          {{betRoundUser.user.formattedName}}
          <span class="subtitle" *ngIf="betRound.admin">{{betRoundUser.user.loginName}}</span>
        </span>
        <span class="table_cell_menu" *ngIf="betRound.admin && betRoundUser.user.id != sharedService.loggedInUser.id">
            <a class="menu_link" (click)="sharedJqueryService.openCloseMenu('user'+ betRoundUser.id);">
              <img class="more_menu" src="assets/matchday/more_menu.png" alt="Manü"/>
              <img class="more_menu" src="assets/matchday/more_menu_hover.png" alt="Menü"/>
            </a>
            <div id="menu_user{{betRoundUser.id}}" class="menu">
              <a class="menu_entry" (click)="openEditUserOverlay(betRoundUser)"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/betround/edit.png" alt="Bearbeiten"/>
								</span> <span class="menu_entry_text"> BEARBEITEN </span>
              </a>
              <a class="menu_entry" (click)="openDeleteUserOverlay(betRoundUser)"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/betround/delete.png" alt="Entfernen"/>
								</span> <span class="menu_entry_text"> ENTFERNEN </span>
              </a>
            </div>
        </span>
      </div>

    </div>
  </div>
</div>

<!-- BEGINN ADDLEAGUE OVERLAY -->
<div id="overlay_add_league_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_add_league" class="overlay_box overlay_box_auto_height">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">LIGA HINZUFÜGEN</span>
    <div class="overlay_ajax_loader">
      <img id="add_league_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">Welchen Wettbewerb wollt ihr tippen?</span>
  </div>
  <div class="overlay_box_content">
    <form class="overlay_form"
          (ngSubmit)="addLeague(); sharedJqueryService.showAjaxLoader('add_league_ajax_loader');">

      <div class="standard_input_text">
        <label class="input_text_label">Wettbewerb</label>
        <select class="select_answer_input" [(ngModel)]="selectedLeagueId" [ngModelOptions]="{standalone: true}">
          <option *ngFor="let league of availableLeagues"
                  [value]="league.id">
            {{league.name}} {{league.saison}}
          </option>
        </select>
      </div>

      <div class="standard_input_button" style="padding-right: 5px;">
        <input type="reset" value="ABBRECHEN"
               (click)="sharedJqueryService.closeAllOverlays();"/>
        <input type="submit" value="HINZUFÜGEN"/>
      </div>
    </form>
  </div>
</div>
<!-- ENDE ADDLEAGUE OVERLAY -->

<!-- BEGINN REMOVE LEAGUE OVERLAY -->
<div id="overlay_delete_league_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_delete_league" class="overlay_box overlay_box_auto_height">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">WETTBEWERB LÖSCHEN</span>
    <div class="overlay_ajax_loader">
      <img id="remove_league_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span
      class="overlay_box_header_subtitle">{{leagueToDelete?.name}} {{leagueToDelete?.saison}} wirklich löschen?</span>
  </div>
  <div class="overlay_box_content">
    <form class="overlay_form"
          (ngSubmit)="deleteLeague(); sharedJqueryService.showAjaxLoader('remove_league_ajax_loader');">
      <div class="standard_input_button" style="padding-right: 5px;">
        <input type="reset" value="ABBRECHEN"
               (click)="sharedJqueryService.closeAllOverlays();"/>
        <input type="submit" class="delete_button" value="LÖSCHEN"/>
      </div>
    </form>
  </div>
</div>
<!-- ENDE REMOVE LEAGUE OVERLAY -->

<!-- BEGINN REMOVE USER OVERLAY -->
<div id="overlay_delete_user_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_delete_user" class="overlay_box overlay_box_auto_height">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">TIPPER LÖSCHEN</span>
    <div class="overlay_ajax_loader">
      <img id="remove_user_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">{{userToDelete?.user.formattedName}} wirklich löschen?</span>
  </div>
  <div class="overlay_box_content">
    <form class="overlay_form"
          (ngSubmit)="deleteBetRoundUser(); sharedJqueryService.showAjaxLoader('remove_user_ajax_loader');">
      <div class="standard_input_button" style="padding-right: 5px;">
        <input type="reset" value="ABBRECHEN"
               (click)="sharedJqueryService.closeAllOverlays();"/>
        <input type="submit" class="delete_button" value="LÖSCHEN"/>
      </div>
    </form>
  </div>
</div>
<!-- ENDE REMOVE USER OVERLAY -->

<!-- BEGINN EDIT USER OVERLAY -->
<div id="overlay_edit_user_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_edit_user" class="overlay_box overlay_box_auto_height">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">TIPPER BEARBEITEN</span>
    <div class="overlay_ajax_loader">
      <img id="edit_user_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">{{userToEdit?.user?.formattedName}}</span>
  </div>
  <div class="overlay_box_content">
    <form class="overlay_form"
          (ngSubmit)="saveBetRoundUser(); sharedJqueryService.showAjaxLoader('edit_user_ajax_loader');">

      <div class="uirepo_checkbox" *ngIf="userToEdit">
        <label>
          <input type="checkbox"
                 [(ngModel)]="userToEdit.admin" [ngModelOptions]="{standalone: true}"/>
          <i class="checkbox_helper"></i>
          <span class="checkbox_label">Eigentümer</span>
          <span class="info">Als Eigentümer hat der Tipper die Berechtigung die Tipprunde zu verwalten und auch zu löschen.</span>
        </label>
      </div>

      <div class="standard_input_button" style="padding-right: 5px;">
        <input type="reset" value="ABBRECHEN"
               (click)="sharedJqueryService.closeAllOverlays();"/>
        <input type="submit" value="SPEICHERN"/>
      </div>
    </form>
  </div>
</div>
<!-- ENDE EDIT USER OVERLAY -->


<!-- BEGINN BONUSTIPPS OVERLAY -->
<div id="overlay_bonus_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_bonus" class="overlay_box overlay_box_auto_height">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">BONUSTIPPS KONFIGURIEREN</span>
    <div class="overlay_ajax_loader">
      <img id="bonus_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">Welche Bonusfragen sollen getippt werden?</span>
  </div>
  <div class="overlay_box_content">
    <form class="overlay_form"
          (ngSubmit)="saveBonusQuestions(); sharedJqueryService.showAjaxLoader('bonus_ajax_loader');">

      <div class="bonus_question_box" *ngFor="let betRoundLeagueBonusQuestion of betRoundLeagueBonusQuestions;">

        <div class="bonus_question">
          <div class="uirepo_checkbox">
            <label>
              <input type="checkbox"
                     [(ngModel)]="betRoundLeagueBonusQuestion.selected" [ngModelOptions]="{standalone: true}"/>
              <i class="checkbox_helper"></i>
              <span class="checkbox_label">{{betRoundLeagueBonusQuestion?.bonusQuestionText}}</span>
            </label>
          </div>
        </div>

        <div class="bonus_points">
          <label class="input_text_label input_text_label_down">Punkte</label>
          <input type="text" class="bonuspoints"
                 [(ngModel)]="betRoundLeagueBonusQuestion.points"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>

      </div>

      <div class="standard_input_button" style="padding-right: 5px;">
        <input type="reset" value="ABBRECHEN"
               (click)="sharedJqueryService.closeAllOverlays();"/>
        <input type="submit" value="SPEICHERN"/>
      </div>
    </form>
  </div>
</div>
<!-- ENDE BONUSTIPPS OVERLAY -->
