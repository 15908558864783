<!-- BEGINN IMPRESSUM OVERLAY -->
<div id="overlay_impressum_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_impressum" class="overlay_box overlay_box_auto_height">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">IMPRESSUM</span>
    <div class="overlay_ajax_loader">
      <img id="impressum_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">
      Über uns
    </span>
  </div>
  <div class="overlay_box_content">

    <div class="rules_box">
      <div class="rule">
        Angaben gemäß § 5 TMG:
        <span class="example">
          Florian Dittmann<br/>
          Am Ebelfeld 230<br/>
          60488 Frankfurt
        </span>

        <span class="example">René Riegler<br/>
        Homburger Landstraße 94<br/>
        60435 Frankfurt</span>

        Bei tippfreun.de handelt es sich um eine nicht kommerzielle Seite!

        <span class="sub_headline">Kontakt</span>
        E-Mail: info@tippfreun.de

        <span class="sub_headline">Quellenangaben für die verwendeten Bilder und Grafiken</span>
        dESIGNNORM & pixabay
      </div>
    </div>

  </div>
</div>
<!-- ENDE IMPRESSUM OVERLAY -->
