<!-- BEGINN DATAPRIVACY OVERLAY -->
<div id="overlay_dataprivacy_background" class="dark_overlay"></div>
<div id="overlay_dataprivacy" class="overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">Datenschutz</span>
    <div class="overlay_ajax_loader">
      <img id="dataprivacy_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <span class="overlay_box_header_subtitle">
      Bitte stimme der neuen Datenschutzerklärung zu!
    </span>
  </div>
  <div class="overlay_box_content">
    <dataPrivacy></dataPrivacy>
    <div class="rules_box" style="border: none;">
      <div class="uirepo_checkbox">
        <label>
          <input type="checkbox"
                 [(ngModel)]="dataPrivacy" [ngModelOptions]="{standalone: true}"/>
          <i class="checkbox_helper"></i>
          <span
            class="checkbox_label agb_label">Ich habe die Datenschutzerklärung gelesen und bin damit einverstanden. Ich bestätige außerdem, dass ich älter als 16 Jahre bin.</span>
        </label>
      </div>
      <br/>
      <div class="uirepo_checkbox">
        <label>
          <input type="checkbox"
                 [(ngModel)]="newsletter" [ngModelOptions]="{standalone: true}"/>
          <i class="checkbox_helper"></i>
          <span class="checkbox_label agb_label">Ich möchte weiterhin den Newsletter erhalten.</span>
        </label>
      </div>
      <div class="standard_input_button" style="padding-right: 5px;">
        <input type="button" class="reset_button" value="ABMELDEN" (click)="logout()"/>
        <input type="button" (click)="acceptDataPrivacy()" value="ABSENDEN"/>
      </div>
    </div>

  </div>
</div>
<!-- ENDE DATAPRIVACY OVERLAY -->
