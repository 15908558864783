<header></header>

<menu [active]="'editBonusQuestions'"></menu>

<div class="content">
  <div class="content_container" *ngIf="bonusQuestionWrappers">


    <div class="league_selection">
      {{bonusQuestionWrapper.league.name}} {{bonusQuestionWrapper.league.saison}}
      <img src="assets/admin/dropdown_arrow.png" alt="Auswählen...">
      <select class="select_league" [(ngModel)]="currentLeagueIndex"
              (ngModelChange)="changeLeague($event)">
        <option *ngFor="let resultLeague of bonusQuestionWrappers; let leagueIndex = index"
                [value]="leagueIndex">
          {{resultLeague.league.name}} {{resultLeague.league.saison}}
        </option>
      </select>
    </div>

    <div class="new_bonusquestion">
      <div class="new_bonusquestion_header">
        <span class="new_bonusquestion_header_content">
        Neue Bonusfrage erstellen...
      </span>
      </div>
      <div class="new_bonusquestion_content">
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down">Frage</label>
          <input type="text" [(ngModel)]="newBonusQuestion.questionText"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down">Punkte</label>
          <input type="datetime-local" [(ngModel)]="newBonusQuestion.dateForFullPoints"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down">1/2 Punkte</label>
          <input type="datetime-local" [(ngModel)]="newBonusQuestion.dateForHalfPoints"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down">1/4 Punkte</label>
          <input type="datetime-local" [(ngModel)]="newBonusQuestion.dateForQuarterPoints"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down">Antworttyp</label>
          <select [(ngModel)]="newBonusQuestion.answerType">
            <option value="1">
              Team
            </option>
          </select>
        </div>
      </div>
      <div class="new_bonusquestion_actions">
        <input class="new_bonusquestion_button" type="button" value="Speichern" (click)="saveBonusQuestion()"/>
      </div>
    </div>

    <div class="bonus">
      <div class="bonus_questions_container"
           *ngFor="let bonusQuestion of bonusQuestionWrapper.bonusQuestions; let bonusIndex = index;">
        <div class="bonus_question_box">
          <div class="bonus_question">
            <span
              class="question">{{bonusQuestion.questionText}}</span>
            <span class="solution"
                  *ngIf="bonusQuestion.answers.length > 0">
              <span class="answer" *ngFor="let answer of bonusQuestion.answers">{{answer.answer}}</span>
            </span>
            <span class="solution"
                  *ngIf="bonusQuestion.answers.length == 0">
              <span><img src="assets/bonus/wait.png" alt="Warten"/>
                <span
                  class="countdown" *ngIf="bonusQuestion.countdown">{{bonusQuestion.countdown}}</span>
                <span class="countdown"
                      *ngIf="!bonusQuestion.countdown">Abgelaufen</span>
              </span>
            </span>
            <span class="select_answer">
                <select class="select_answer_input" [(ngModel)]="bonusQuestion.answer"
                        (ngModelChange)="saveAnswer($event, bonusQuestion, bonusIndex)">
                  <option selected disabled>Beantworten</option>
                  <option *ngFor="let answer of bonusQuestionWrapper.teams"
                          [value]="answer">
                  {{answer}}
                  </option>
                </select>
              </span>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
