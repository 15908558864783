<header></header>

<menu [active]="'newsletter'"></menu>

<div class="content">
  <div class="content_container">

    Newsletter


  </div>
</div>
