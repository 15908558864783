<div id="coockieInfo" class="cookies">
  <span class="cookies_info">
    Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können
    und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Nutzung unserer Website
    an unsere Partner für soziale Medien, Werbung und Analysen weiter.
    <span class="cookies_link_container"><a class="cookies_link" (click)="allowCoockies()">ich stimme zu</a></span>
  </span>
</div>

<div class="header">
  <div class="header_logo">
    <img class="logo_header" src="assets/home/logo.png" alt="Tippfreunde Logo"/>
  </div>
  <div class="header_app">
    <a href="https://play.google.com/store/apps/details?id=de.tippfreunde&hl=de" target="_blank">
      <img class="store_img" src="assets/home/play_store.svg" alt="Google Playstore"/>
    </a>
    <!--
    <a href="https://play.google.com/store/apps/details?id=de.tippfreunde&hl=de" target="_blank">
      <img class="store_img" src="assets/home/app_store.svg" alt="App Store"/>
    </a>
    -->
  </div>
</div>

<div class="teaser">
  <img class="logo_teaser" src="assets/home/logo.png" alt="Tippfreunde Logo"/>

  <h1>Dein eigenes Tippspiel</h1>
  <h2>Kostenlos registrieren, Tipprunde erstellen, Freunde einladen und auf spannende Spiele
    der Fussball Bundesliga, Champions League, DFB-Pokal, EM und WM tippen.
  </h2>
  <div class="login_box">
    <a class="login_link" (click)="sharedJqueryService.openCloseOverlay('overlay_login', 'show');">Anmelden</a>
  </div>
  <div class="register_info">Noch kein Konto? <a class="register_link"
                                                 (click)="sharedJqueryService.openCloseOverlay('overlay_register', 'show');">Jetzt
    Registrieren</a></div>
</div>

<div class="img_container">
  <div class="img_box">
    <img class="img" src="assets/home/freunde.jpg" alt="Freunde"/>
    <span class="img_headline">Freunde</span>
  </div>
  <div class="img_box">
    <img class="img" src="assets/home/plattformen.jpg" alt="Plattformen"/>
    <span class="img_headline">Überall</span>
  </div>
  <div class="img_box">
    <img class="img" src="assets/home/freiheit.jpg" alt="Freiheit"/>
    <span class="img_headline">Freiheiten</span>
  </div>
  <div class="img_box">
    <img class="img" src="assets/home/kostenlos.jpg" alt="Kostenlos"/>
    <span class="img_headline">Kostenlos</span>
  </div>
</div>

<div class="img_container">
  <div class="img_box">
    <span class="text">Tippe mit deinen Freunden in eurer eigenen Tipprunde und kämpft um den Sieg.</span>
  </div>
  <div class="img_box">
    <span class="text">Tippe an deinem Computer oder über dein Smartphone. Egal wo du bist - tippfreunde ist immer bei dir!</span>
  </div>
  <div class="img_box">
    <span class="text">Du hast in deiner Tipprunde alle Freiheiten. Von tippbaren Wettbewerben bis hin zu Bonustipps, alles ist dir selbst überlassen.</span>
  </div>
  <div class="img_box">
    <span class="text">Tippfreunde ist für dich und deine Freunde komplett kostenlos.</span>
  </div>
</div>

<div class="social_media">
  <span class="social_media_headline">Verpasse keine Neuigkeiten - Folge uns auf Facebook und Google+</span>
  <div class="social_media_icons">
    <a class="social_media_link" href="https://www.facebook.com/tippfreun.de.5" target="_blank">
      <img src="assets/home/fb.png" alt="Facebook"/>
    </a>
    <a class="social_media_link" href="https://plus.google.com/u/0/111841584056472957239/about" target="_blank">
      <img src="assets/home/google+.png" alt="Google+"/>
    </a>
  </div>
</div>

<div class="features">
  <span class="feature_teaser">
    Um auf tippfreunde tippen zu können, musst Du mindestens Mitglied einer Tipprunde sein.
    Dafür musst Du entweder selbst eine Tipprunde erstellen oder einer Tipprunde beitreten.
    In dieser tippen dann nur die Mitglieder untereinander.
  </span>

  <div class="feature_headline">Jede Tipprunde hat....</div>
  <span class="feature_point">ein eigenes Ranking</span>
  <span class="feature_point">eine eigene Tippstatistik</span>
  <span class="feature_point">einen eigenen News-Feed</span>
  <span class="feature_point">eigene Bonustipps</span>

  <div class="feature_headline">Zusätzlich erwarten dich weitere Features...</div>
  <span class="feature_point">tippe viele Ligen und Pokalwettbewerbe inklusive EM und WM</span>
  <span class="feature_point">schalte Deine Mitspieler frei oder sperre sie</span>
  <span class="feature_point">wähle die Punkteberechnung aus</span>
  <span class="feature_point">sieh dir nach Deiner Tippabgabe die Tipps der anderen Mitspieler an</span>
  <span class="feature_point">schau Dir das aktuelle oder das Live-Ranking an</span>
  <span class="feature_point">verfolge die laufenden Spiele mit dem Live Ergebnis-Service</span>
  <span class="feature_point">und vieles mehr...</span>
</div>

<div class="footer">
  <div class="footer_container">
    <a class="footer_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_contact', 'show');">Kontakt</a>
    <a class="footer_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_agb', 'show');">AGB & Datenschutzerklärung</a>
    <a class="footer_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_impressum', 'show');">Impressum</a>
  </div>
</div>


<!-- BEGINN ANMELDEN OVERLAY -->
<div id="overlay_login_background" class="dark_overlay" (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_login" class="overlay_box small_overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">ANMELDEN</span>
    <div class="overlay_ajax_loader">
      <img id="login_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle" *ngIf="!activationSuccess && !newPasswordSuccess">Bitte melde dich mit deinen Nutzdaten an!</span>
    <span class="overlay_box_header_subtitle register_subtitle" *ngIf="activationSuccess">Registrierung erfolgreich! Du kannst dich jetzt anmelden!</span>
    <span class="overlay_box_header_subtitle register_subtitle" *ngIf="newPasswordSuccess">Dein neues Passwort wurde dir per E-Mail gesendet!</span>
  </div>
  <div class="overlay_box_content">
    <div class="content_container">
      <form class="overlay_form" (ngSubmit)="login(); sharedJqueryService.showAjaxLoader('login_ajax_loader');">
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down" for="userId">E-Mail</label>
          <input id="userId" type="text" [(ngModel)]="loginUserId"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down" for="password">Passwort</label>
          <input id="password" type="password" [(ngModel)]="loginPassword"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <a class="forget_password"
           (click)="sharedJqueryService.closeAllOverlays(); sharedJqueryService.openCloseOverlay('overlay_forget_password', 'show');">Passwort
          vergessen?</a>
        <div class="standard_input_button" style="padding-right: 5px;">
          <input type="button" class="reset_button" value="ABBRECHEN"
                 (click)="sharedJqueryService.closeAllOverlays();"/>
          <input type="submit" value="ANMELDEN"/>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- ENDE ANMELDEN OVERLAY -->

<!-- BEGINN PASSWORT VERGESSEN OVERLAY -->
<div id="overlay_forget_password_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_forget_password" class="overlay_box small_overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">PASSWORT VERGESSEN</span>
    <div class="overlay_ajax_loader">
      <img id="forget_pw_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">Bitte gib deine E-Mail Adresse ein!</span>
  </div>
  <div class="overlay_box_content">
    <div class="content_container">

      <form class="overlay_form"
            (ngSubmit)="forgetPassword(); sharedJqueryService.showAjaxLoader('forget_pw_ajax_loader');">
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down" for="pwUserId">E-Mail</label>
          <input type="text" [(ngModel)]="forgetPasswordUserId" id="pwUserId"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_button" style="padding-right: 5px;">
          <input type="button" class="reset_button" value="ABBRECHEN"
                 (click)="sharedJqueryService.closeAllOverlays();"/>
          <input type="submit" value="ANFORDERN"/>
        </div>
      </form>

    </div>
  </div>
</div>
<!-- ENDE PASSWORT VERGESSEN OVERLAY -->

<!-- BEGINN REGISTRIEREN OVERLAY -->
<div id="overlay_register_background" class="dark_overlay" (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_register" class="overlay_box small_overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">REGISTRIEREN</span>
    <div class="overlay_ajax_loader">
      <img id="register_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">Lege dein eigenes tippfreunde Konto an!</span>
  </div>
  <div class="overlay_box_content">
    <div class="content_container">

      <form class="overlay_form" (ngSubmit)="register(); sharedJqueryService.showAjaxLoader('register_ajax_loader');">
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down" for="registerUserId">E-Mail*</label>
          <input type="text" [(ngModel)]="registerUserId" id="registerUserId"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down" for="registerPassword">Passwort*</label>
          <input type="password" [(ngModel)]="registerPassword" id="registerPassword"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down two_lines" for="registerPasswordRepeat">Passwort wiederholen*</label>
          <input type="password" [(ngModel)]="registerPasswordRepeat" id="registerPasswordRepeat"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down" for="firstname">Vorname*</label>
          <input type="text" [(ngModel)]="registerFirstName" id="firstname"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down" for="name">Nachname*</label>
          <input type="text" [(ngModel)]="registerLastName" id="name" [ngModelOptions]="{standalone: true}" required/>
        </div>

        <div class="uirepo_checkbox">
          <label>
            <input type="checkbox"
                   [(ngModel)]="registerAGB" [ngModelOptions]="{standalone: true}"/>
            <i class="checkbox_helper"></i>
            <span class="checkbox_label agb_label">Ich habe die <a class="agb_link"
                                                                   (click)="sharedJqueryService.openCloseOverlay('overlay_agb', 'show');">Datenschutzerklärung</a>
            und die <a class="agb_link"
                       (click)="sharedJqueryService.openCloseOverlay('overlay_agb', 'show');">Allgemeinen
              Geschäftsbedingungen</a>
            gelesen und bin damit einverstanden. Ich bestätige außerdem, dass ich älter als 16 Jahre bin.</span>
          </label>
        </div>
        <div class="standard_input_button" style="padding-right: 5px;">
          <input type="button" class="reset_button" value="ABBRECHEN"
                 (click)="sharedJqueryService.closeAllOverlays();"/>
          <input type="submit" value="REGISTRIEREN"/>
        </div>
      </form>

    </div>
  </div>
</div>
<!-- ENDE REGISTRIEREN OVERLAY -->


<!-- BEGINN PASSWORT VERGESSEN OVERLAY -->
<div id="overlay_unsubscribe_newsletter_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_unsubscribe_newsletter" class="overlay_box small_overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">NEWSLETTER ABMELDUNG</span>
    <div class="overlay_ajax_loader">
      <img id="unsubscribe_newsletter_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">Willst du dich wirklich vom Newsletter abmelden?</span>
  </div>
  <div class="overlay_box_content">
    <div class="content_container">

      <form class="overlay_form"
            (ngSubmit)="unsubscribeNewsletter(); sharedJqueryService.showAjaxLoader('unsubscribe_newsletter_ajax_loader');">
        <div class="standard_input_button" style="padding-right: 5px;">
          <input type="button" class="reset_button" value="ABBRECHEN"
                 (click)="sharedJqueryService.closeAllOverlays();"/>
          <input type="submit" value="ABMELDEN"/>
        </div>
      </form>

    </div>
  </div>
</div>
<!-- ENDE PASSWORT VERGESSEN OVERLAY -->


<contact></contact>
<impressum></impressum>
<agb></agb>

<!-- NOTIFICATIONS -->
<simple-notifications [options]="notificationOptions"></simple-notifications>
