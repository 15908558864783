<header></header>

<menu [active]="'ranking'"></menu>

<div class="content">

  <div class="matchday_container" (swipeleft)="nextMatchday()" (swiperight)="previousMatchday()">

    <div class="matchday_slider" *ngIf="ranking">
      <span (click)="previousMatchday()" class="matchday_slider_arrow"><img src="assets/matchday/left.png" alt="Pfeil nach Links"/></span>
      <span class="matchday_slider_label">{{sharedService.currentMatchDay}}</span>
      <span (click)="nextMatchday()" class="matchday_slider_arrow"><img src="assets/matchday/right.png" alt="Pfeil nach Rechts"/></span>
    </div>

    <span class="reload_button"
          (click)="sharedJqueryService.showAjaxLoader('reload_ajax_loader'); loadRanking();"><img
      src="assets/matchday/reload.png" alt="Aktualisieren"/></span>
    <span id="reload_ajax_loader" class="reload_ajax_loader"><img
      alt="Bitte warten..."
      src="assets/matchday/reload_ajax_loader.gif"/></span>

    <div class="matchday">

      <div class="users_container">
        <div class="user_box" *ngFor="let rankingUser of ranking; let rankingUserIndex = index;">
          <div class="user">
            <span class="user_img"> <img style="border-radius: 25px; vertical-align: bottom" alt="Nutzerbild"
                                         src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=40&amp;userId={{rankingUser?.user?.id}}&amp;randomImageId={{sharedService.randomImageId}}"></span>
            <span class="place">{{rankingUser.rankingNr}}.</span>
            <span class="user_name">{{rankingUser.user.formattedName}}<span class="betpercent">{{rankingUser.formattedBetRateForExactBets}}% / {{rankingUser.formattedBetRateForDifferenceBets}}% / {{rankingUser.formattedBetRateForTrendBets}}% /{{rankingUser.formattedBetRateForFailureBets}}%</span></span>
            <span class="bonuspoints">{{rankingUser.bonusPoints}} <span class="point_label">Bonuspkt.</span></span>
            <span class="matchdaypoints">{{rankingUser.userBetPointsForMatchDay}} <span class="live_points"
                                                                                        *ngIf="rankingUser.liveGames">(+{{rankingUser.livePoints}})</span> <span
              class="point_label">Tagespkt.</span></span>
            <span class="points">{{rankingUser.points + rankingUser.livePoints}} <span
              class="point_label">Gesamtpkt.</span></span>
            <a class="menu_link" (click)="sharedJqueryService.openCloseMenu(rankingUser.user.id);">
              <img class="more_menu" src="assets/matchday/more_menu.png" alt="Menü"/>
              <img class="more_menu" src="assets/matchday/more_menu_hover.png" alt="Menü"/>
            </a>
            <div id="menu_{{rankingUser.user.id}}" class="menu">
              <a class="menu_entry"
                 (click)="openUsermatchdaybetstatistik(rankingUser.user)"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/ranking/chart.png" alt="Speichern - Spieltagstipps"/>
								</span> <span class="menu_entry_text"> SPIELTAGSTIPPS </span>
              </a>
              <!--
              <a class="menu_entry"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/ranking/joker.png"/>
								</span> <span class="menu_entry_text"> JOKER </span>
              </a>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TODO USER ÜBERGEBEN -->
<usermatchdaybetstatistik [user]="selectedUser" (closed)="clearSelectedUser()"></usermatchdaybetstatistik>
