<header></header>

<menu [active]="'bonus'"></menu>

<div class="content">

  <div class="bonus_container">

    <div class="bonus">

      <div class="bonus_questions_container"
           *ngFor="let bonusQuestionContainer of bonusQuestions; let bonusIndex = index;">
        <div class="bonus_question_box">
          <div class="bonus_question">
            <span
              class="question">{{bonusQuestionContainer.betRoundLeagueBonusQuestion.bonusQuestion.questionText}}</span>
            <span class="solution"
                  *ngIf="bonusQuestionContainer.betRoundLeagueBonusQuestion.bonusQuestion.answers.length > 0">
              <span class="solution_answer"
                    *ngFor="let answer of bonusQuestionContainer.betRoundLeagueBonusQuestion.bonusQuestion.answers">{{answer.answer}}</span>
            </span>
            <span class="solution"
                  *ngIf="bonusQuestionContainer.betRoundLeagueBonusQuestion.bonusQuestion.answers.length == 0">
              <span><img src="assets/bonus/wait.png" alt="Warten"/>
                <span
                  class="countdown" *ngIf="bonusQuestionContainer.betRoundLeagueBonusQuestion.bonusQuestion.countdown">{{bonusQuestionContainer.betRoundLeagueBonusQuestion.bonusQuestion.countdown}}</span>
                <span class="countdown"
                      *ngIf="!bonusQuestionContainer.betRoundLeagueBonusQuestion.bonusQuestion.countdown">Abgelaufen</span>
              </span>
            </span>

            <span class="answer"
                  *ngIf="(!bonusQuestionContainer.betable || bonusQuestionContainer.userBonusQuestionBet?.fixedBet || bonusQuestionContainer.userBonusQuestionBet?.formattedPoints) && bonusQuestionContainer.userBonusQuestionBet.answer">{{bonusQuestionContainer.userBonusQuestionBet.answer}}</span>
            <span class="answer"
                  *ngIf="!bonusQuestionContainer.userBonusQuestionBet?.formattedPoints && bonusQuestionContainer.betable && !bonusQuestionContainer.userBonusQuestionBet?.fixedBet && bonusQuestionContainer.userBonusQuestionBet?.answer">
              <span class="select_answer">
                <select class="select_answer_input" [(ngModel)]="bonusQuestionContainer.userBonusQuestionBet.answer"
                        (ngModelChange)="saveAnswer($event, bonusQuestionContainer)">
                  <option *ngFor="let answer of answerPossibilities"
                          [value]="answer">
                  {{answer}}
                </option>
                </select>
              </span>
            </span>
            <span class="answer"
                  *ngIf="(!bonusQuestionContainer.betable || bonusQuestionContainer.userBonusQuestionBet?.fixedBet || bonusQuestionContainer.userBonusQuestionBet?.formattedPoints) && !bonusQuestionContainer.userBonusQuestionBet?.answer">Keine Antwort</span>
            <span class="answer"
                  *ngIf="!bonusQuestionContainer.userBonusQuestionBet?.formattedPoints && bonusQuestionContainer.betable && !bonusQuestionContainer.userBonusQuestionBet?.fixedBet && !bonusQuestionContainer.userBonusQuestionBet?.answer">
              <span class="select_answer">
                <select class="select_answer_input" [(ngModel)]="bonusQuestionContainer.userBonusQuestionBet.answer"
                        (ngModelChange)="saveAnswer($event, bonusQuestionContainer)">
                  <option *ngFor="let answer of answerPossibilities"
                          [value]="answer">
                  {{answer}}
                  </option>
                </select>
                <span class="no_answer" *ngIf="!bonusQuestionContainer.userBonusQuestionBet.answer">Beantworten</span>
              </span>
            </span>
            <span class="locked"
                  *ngIf="(!bonusQuestionContainer.betable || bonusQuestionContainer.userBonusQuestionBet?.fixedBet) && !bonusQuestionContainer.userBonusQuestionBet?.formattedPoints && bonusQuestionContainer.betRoundLeagueBonusQuestion.bonusQuestion.answers.length == 0"><img
              src="assets/bonus/locked.png" alt="Gesperrt"/><span
              class="possible_points">{{bonusQuestionContainer.betRoundLeagueBonusQuestion.points}} Punkte</span>
            </span>
            <span class="unlocked"
                  *ngIf="!bonusQuestionContainer.userBonusQuestionBet?.formattedPoints && bonusQuestionContainer.betable && !bonusQuestionContainer.userBonusQuestionBet?.fixedBet"><img
              src="assets/bonus/unlocked.png" alt="Freigegeben"/><span
              class="possible_points">{{bonusQuestionContainer.betRoundLeagueBonusQuestion.points}} Punkte</span></span>
            <span class="points" *ngIf="bonusQuestionContainer.userBonusQuestionBet?.formattedPoints">
              <span
                [ngClass]="{'three_points': bonusQuestionContainer.userBonusQuestionBet?.formattedPoints != 0,'zero_points': bonusQuestionContainer.userBonusQuestionBet?.formattedPoints==0 }">{{bonusQuestionContainer.userBonusQuestionBet?.formattedPoints}} <span
                class="point_label">Punkte</span></span></span>
            <span class="points"
                  *ngIf="!bonusQuestionContainer.userBonusQuestionBet?.formattedPoints && !bonusQuestionContainer.betable && bonusQuestionContainer.betRoundLeagueBonusQuestion.bonusQuestion.answers.length > 0">
              <span class="zero_points">0 <span class="point_label">Punkte</span></span>
            </span>
            <a class="menu_link" (click)="sharedJqueryService.openCloseMenu(bonusIndex);">
              <img class="more_menu" src="assets/matchday/more_menu.png" alt="Menü"/>
              <img class="more_menu" src="assets/matchday/more_menu_hover.png" alt="Menü"/>
            </a>
            <div id="menu_{{bonusIndex}}" class="menu">
              <a class="menu_entry"
                 (click)="setSelectedBetRoundLeagueBonusQuestionId(bonusQuestionContainer.betRoundLeagueBonusQuestion.id);"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/bonus/save.png" alt="Speichern"/>
								</span> <span class="menu_entry_text"> TIPPSTATISTIK </span>
              </a>
              <!--
              <a class="menu_entry"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/bonus/joker.png"/>
								</span> <span class="menu_entry_text"> JOKER </span>
              </a>
              -->
            </div>
            <span id="bet_ajax_loader_{{bonusQuestionContainer.betRoundLeagueBonusQuestion.id}}"
                  class="bet_ajax_loader">
              <img class="Bitte warten..." src="assets/matchday/bet_ajax_loader.gif"/>
            </span>
          </div>
        </div>
      </div>

      <div class="not_bonusquestions" *ngIf="bonusQuestions?.length == 0">
        In dieser Tipprunde sind keine Bonusfragen konfiguriert!
      </div>

    </div>
  </div>
</div>

<!-- TODO BONUSFRAGE ÜBERGEBEN -->
<bonusbetstatistik [betRoundLeagueBonusQuestionId]="selectedBetRoundLeagueBonusQuestionId"
                   (close)="clearSelectedBetRoundLeagueBonusQuestionId()"></bonusbetstatistik>


