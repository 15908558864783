<header></header>

<menu [active]="'comments'"></menu>

<div class="content">

  <div class="content_container">

    <div class="new_comment" *ngIf="comments">
      <div class="new_comment_header">
        <span class="new_comment_header_content">
        Neuen Kommentar schreiben...
          <img style="border-radius: 25px; vertical-align: bottom" class="new_comment_user" alt="Nutzerbild"
               src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=30&amp;userId={{sharedService.loggedInUser.id}}&amp;randomImageId={{sharedService.randomImageId}}">
      </span>
      </div>
      <div class="new_comment_content">
      <textarea class="new_comment_textarea" type="text" [(ngModel)]="newcomment"
                [ngModelOptions]="{standalone: true}" required placeholder="Was willst du sagen?"></textarea>
      </div>
      <div class="new_comment_actions">
        <input class="new_comment_button" type="button" value="POSTEN" (click)="saveComment()"/>
      </div>
    </div>


    <div class="comments">
      <div class="comments_container">
        <div class="comment_box" *ngFor="let comment of comments; let commentIndex = index;">
          <div class="comment">
                        <span class="user_img"> <img style="border-radius: 25px; vertical-align: bottom" alt="Nutzerbild"
                                                     src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=40&amp;userId={{comment.userId}}&amp;randomImageId={{sharedService.randomImageId}}"></span>
            <span class="user_name">{{comment.user?.formattedName}}<span
              class="date">{{comment.formattedCreateStamp}}</span></span>
            <span class="comments_count">{{comment.childCommentCount}} <span
              class="comment_label">Kommentare</span></span>
            <span class="loala_count">{{comment.fanTribuneLaOlas.length}} <span
              class="comment_label">LaOlas</span></span>
            <a class="menu_link" (click)="sharedJqueryService.openCloseMenu(commentIndex);">
              <img class="more_menu" src="assets/comment/more_menu.png" alt="Menü"/>
              <img class="more_menu" src="assets/comment/more_menu_hover.png" alt="Menü"/>
            </a>
            <div id="menu_{{commentIndex}}" class="menu">
              <a class="menu_entry" (click)="setSelectedCommentId(comment.id)"> <span
                class="menu_entry_icon">
                                                  <img class="menu_entry_icon" src="assets/comment/comment.png" alt="Kommentieren"/>
                                              </span> <span class="menu_entry_text"> KOMMENTIEREN </span>
              </a>
              <a class="menu_entry" (click)="toogleLaola(comment.id, commentIndex);"> <span
                class="menu_entry_icon">
                                                  <img class="menu_entry_icon" src="assets/comment/laola.png" alt="Laola"/>
                                              </span> <span class="menu_entry_text"> LaOla </span>
              </a>
              <a class="menu_entry" *ngIf="comment.userId == sharedService.loggedInUser.id"
                 (click)="deleteComment(comment.id)"> <span
                class="menu_entry_icon">
                                                  <img class="menu_entry_icon" src="assets/comment/delete.png" alt="Löschen"/>
                                              </span> <span class="menu_entry_text"> LÖSCHEN </span>
              </a>
            </div>
          </div>
          <div class="comment_text">
            {{comment.comment}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TODO GAME ÜBERGEBEN -->
<comment [commentId]="selectedCommentId" (close)="clearSelectedCommentId()"></comment>
