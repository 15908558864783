<header></header>

<menu [active]="'profile'"></menu>

<div class="content">
  <div class="content_container" *ngIf="user">
    <div class="info_container left_container">
      <span class="headline first_headline">Daten ändern</span>
      <span class="info">Hier kannst du deinen Namen und wichtige Einstellungen ändern. Diese werden nach dem Speichern aktiv.</span>
      <div class="standard_input_text">
        <label class="input_text_label edit_in_place" for="email">E-Mail</label>
        <input id="email" type="text" class="edit_in_place" [(ngModel)]="user.loginName"
               [ngModelOptions]="{standalone: true}" disabled/>
      </div>
      <div class="standard_input_text">
        <label class="input_text_label edit_in_place" for="firstName">Vorname</label>
        <input id="firstName" type="text" class="edit_in_place" [(ngModel)]="user.firstname"
               [ngModelOptions]="{standalone: true}" required/>
      </div>
      <div class="standard_input_text">
        <label class="input_text_label edit_in_place" for="lastName">Nachname</label>
        <input id="lastName" type="text" class="edit_in_place" [(ngModel)]="user.lastname"
               [ngModelOptions]="{standalone: true}" required/>
      </div>
      <div class="uirepo_checkbox">
        <label>
          <input type="checkbox"
                 [(ngModel)]="user.betOneTimesForGame" [ngModelOptions]="{standalone: true}"/>
          <i class="checkbox_helper"></i>
          <span class="checkbox_label">Spiele-Tipp für andere Tipprunden übernehmen</span>
          <span class="info"> Wenn Du diese Option aktivierst, dann tippst Du alle Spiele nur ein Mal. Solltest Du also Mitglied in mehreren
        Tipprunden sein, in der die gleiche Liga getippt wird, dann musst Du das Spiel nur ein Mal tippen und Dein Tipp
          wird für die anderen Tipprunden übernommen.</span>
        </label>
      </div>
      <div class="uirepo_checkbox">
        <label>
          <input type="checkbox"
                 [(ngModel)]="user.betReminder" [ngModelOptions]="{standalone: true}"/>
          <i class="checkbox_helper"></i>
          <span class="checkbox_label">Tipperinnerung erhalten</span>
          <span class="info">Wenn Du diese Option aktivierst, dann erhälst Du eine Tipperinnerung, wenn du noch nicht getippt hast.</span>
        </label>
      </div>
      <div class="uirepo_checkbox">
        <label>
          <input type="checkbox"
                 [(ngModel)]="user.newsletterSubscribed" [ngModelOptions]="{standalone: true}"/>
          <i class="checkbox_helper"></i>
          <span class="checkbox_label">Newsletter abbonieren</span>
          <span class="info">Wenn Du diese Option aktivierst, dann erhälst Du den Newsletter von tippfreun.de. Ich habe die <a class="agb_link"
          (click)="sharedJqueryService.openCloseOverlay('overlay_agb', 'show');">Datenschutzerklärung</a> gelesen und bin damit einverstanden.</span>
        </label>
      </div>
      <div>
        <input type="button" value="Speichern" (click)="saveProfile()"/>
      </div>

      <span class="headline">Bild ändern</span>
      <span class="info">Hier kannst du dein Bild ändern. Dieses wird dann direkt überall geändert.</span>
      <img-cropper #cropper [hidden]="!cropperActive" [image]="croppedImageData" [settings]="cropperSettings"
                   (onCrop)="cropped($event)"></img-cropper>
      <div class="image_cropper_buttons">
        <input type="button" value="Abbrechen" class="reset_button"
               *ngIf="cropperActive" (click)="closeCropper()"/>
        <input type="button" value="Speichern"
               *ngIf="cropperActive" (click)="upload()"/>
      </div>
      <div class="main_img" *ngIf="!cropperActive">
        <img class="favorite_img" alt="Nutzerbild"
             src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=150&amp;userId={{user?.id}}&amp;randomImageId={{sharedService.randomImageId}}"
             *ngIf="user.hasPhoto && !cropperActive"/>
        <span class="no_favorite_img" *ngIf="!user.hasPhoto && !cropperActive">Noch kein Bild vorhanden</span>

        <div class="new_img">
          <span class="add_image">Bild auswählen und hochladen</span>
          <input type="file" name="file_upload" (change)="fileUpload($event)" class="upload"
                 accept=".jpg,.jpeg,.png">
        </div>
      </div>
    </div>

    <div class="info_container right_container">

      <span class="headline first_headline">Passwort ändern</span>
      <span class="info">Vergib ein neues Passwort für Dein Konto. Gilt auch für die Smartphone-APP.
      Dein neues Passwort ist direkt nach der Änderung aktiv. Wenn Du die tippfreun.de APP verwendest, dann musst Du
        dich anschließend in der APP erneut authentifizieren.</span>
      <div class="standard_input_text">
        <label class="input_text_label edit_in_place" for="oldPasswort">Altes Passwort</label>
        <input id="oldPasswort" type="password" class="edit_in_place" [(ngModel)]="oldPassword"
               [ngModelOptions]="{standalone: true}" required placeholder="Bitte eingeben..."/>
      </div>
      <div class="standard_input_text">
        <label class="input_text_label edit_in_place" for="newPassword">Passwort</label>
        <input id="newPassword" type="password" class="edit_in_place" [(ngModel)]="newPassword"
               [ngModelOptions]="{standalone: true}" required placeholder="Bitte eingeben..."/>
      </div>
      <div class="standard_input_text">
        <label class="input_text_label edit_in_place" for="newPasswordRepeat">Wiederholen</label>
        <input id="newPasswordRepeat" type="password" class="edit_in_place" [(ngModel)]="newPasswordRepeat"
               [ngModelOptions]="{standalone: true}" required placeholder="Bitte eingeben..."/>
      </div>
      <input type="button" value="Ändern" (click)="changePassword()"/>

      <span class="headline"> Konto löschen</span>
      <span class="info">Hier kannst du dein Konto unwiderruflich löschen. Deine Daten werden sofort gelöscht und du hast keine Möglichkeit
        deine Daten wiederherzustellen.</span>
      <input type="button" class="delete_button" value="Löschen"
             (click)="sharedJqueryService.openCloseOverlay('overlay_delete_profile', 'show');"/>


      <span class="headline">Abmelden</span>
      <span class="info">Hier kannst du dich abmelden.</span>
      <input type="button" value="Abmelden" (click)="logout()"/>

    </div>
  </div>
</div>

<!-- BEGINN DELETE OVERLAY -->
<div id="overlay_delete_profile_background" class="dark_overlay" (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_delete_profile" class="overlay_box" style="bottom: auto;">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">KONTO LÖSCHEN</span>
    <div class="overlay_ajax_loader">
      <img id="deleteaccount_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">Konto wirklich löschen?</span>
  </div>
  <div class="overlay_box_content">
    <form class="overlay_form" (ngSubmit)="deleteProfile(); sharedJqueryService.showAjaxLoader('login_ajax_loader');">
      <div class="standard_input_button" style="padding-right: 5px;">
        <input type="reset" value="ABBRECHEN"
               (click)="sharedJqueryService.closeAllOverlays();"/>
        <input type="submit" class="delete_button" value="LÖSCHEN"/>
      </div>
    </form>
  </div>
</div>
<!-- ENDE DELETE OVERLAY -->
