<!-- BEGINN REGELN OVERLAY -->
<div id="overlay_rules_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_rules" class="overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">REGELN</span>
    <div class="overlay_ajax_loader">
      <img id="rules_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">
      Was musst du wissen? Was solltest du beachten?
    </span>
  </div>
  <div class="overlay_box_content">

    <div class="headline">Tipprunden-Allgemein</div>
    <div class="rules_box">
      <div class="rule">
        <span class="list">&bull; Jeder Spieler kann beliebig viele Tipprunden anlegen.</span>
        <span class="list">&bull; Jede Tipprunde kann beliebig viele verschiedene Wettbewerbe (Bundesliga, DFB-Pokal, etc.) enthalten.</span>
      </div>
    </div>

    <div class="headline">Tippabgabe</div>
    <div class="rules_box">
      <div class="rule">
        <span class="list">&bull; Getippt werden kann bis kurz vor offizieller Anpfiffzeit.</span>
        <span class="example">Bsp: Die Samstags Spiele der Bundesliga beginnen um 15:30 Uhr. Ihr müsst also spätestens um 15:29:59 Uhr euren Tipp abgeben.</span>
        <span class="list">&bull; Wird kein Tipp abgegeben wird das Spiel nicht gewertet.</span>
        <span class="list">&bull; Einmal getippt, und der Tipp kann nicht mehr geändert werden.</span>
        <span class="list">&bull; Nach dem Tippen eines Spiels sind die anderen Tipps der Mitspieler für dieses Spiel sichtbar.</span>
      </div>
    </div>

    <div class="headline">Punktesystem</div>
    <div class="rules_box">
      <div class="rule">
        Das Punktesystem ist in der Tipprunde einstellbar. Es gibt folgende Varianten:

        <span class="sub_headline">Variante 1 (310)</span>
        <span class="list">&bull; Richtiges Ergebnis: 3 Punkte</span>
        <span class="list">&bull; Tendenz: 1 Punkt</span>
        <span class="list">&bull; Falsches Ergebnis: 0 Punkte</span>

        <span class="sub_headline">Variante 2 (3210)</span>
        <span class="list">&bull; Richtiges Ergebnis: 3 Punkte</span>
        <span class="list">&bull; Richtige Differenz: 2 Punkte (Unentschieden ausgenommen)</span>
        <span class="list">&bull; Richtige Tendenz: 1 Punkt</span>
        <span class="list">&bull; Falsches Ergebnis: 0 Punkte</span>

        <span class="sub_headline">Variante 3 (3210)</span>
        <span class="list">&bull; Richtiges Ergebnis: 3 Punkte</span>
        <span class="list">&bull; Richtige Differenz: 2 Punkte (Unentschieden eingeschlossen)</span>
        <span class="list">&bull; Richtige Tendenz: 1 Punkt</span>
        <span class="list">&bull; Falsches Ergebnis: 0 Punkte</span>

        <br/>
        Abgebrochene Spiele werden bei der Auswertung nicht berücksichtigt, es sei denn, sie werden nachgeholt. Dann
        geht das Ergebnis der Neuansetzung als Spielergebnis in die Wertung ein.
      </div>
    </div>

    <div class="headline">Sonderregeln Pokalwettbewerb, EM und WM</div>
    <div class="rules_box">
      <div class="rule">
        Spiele, die auch in der Verlängerung oder im Elfmeterschießen entschieden werden können, sollen mit dem
        Endergebnis (einschließlich Verlängerung OHNE Elfmeterschießen) getippt werden.
        <span class="example">Bsp.: Dein Tipp 2:1! Du erhältst also 3 Punkte wenn das Spiel nach 90 Minuten oder nach 120 Minuten 2:1 endet.</span>
      </div>
    </div>

    <div class="headline">Teilnahmegebühr / Gewinn</div>
    <div class="rules_box">
      <div class="rule">
        Die Teilnahmegebühr kann jeder Tipprunden-Eigentümer selbst festlegen. Die Gewinnberechnung und Ausschüttung bleibt fürs erste jeder Tipprunde selbst überlassen.
      </div>
    </div>

    <div class="headline">Ranking</div>
    <div class="rules_box">
      <div class="rule">
        Ausschlaggebend für das Ranking sind die erzielten Punkte, von den abgegebenen und ausgewerteten Tipps.

        Bei Punktgleichstand überprüft das System die Anzahl der korrekten Tipps. (3 Punkte)
      </div>
    </div>

  </div>
</div>
<!-- ENDE REGELN OVERLAY -->
