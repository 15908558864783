<header></header>

<menu [active]="'more'"></menu>

<div class="content">

  <div class="content_container">
    <div class="more_menu">
      <div class="more_container">
        <div class="more_box">
          <div class="more">
            <a class="more_entry" [routerLink]="['/changebetround']">Tipprunde wechseln<span class="info">Eine Übersicht deiner aktuellen Tipprunden zum Wechseln</span></a>
          </div>
        </div>

        <div class="more_box">
          <div class="more">
            <a class="more_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_newbetround', 'show');">Neue
              Tipprunde<span class="info">Eine neue Tipprunde erstellen</span></a>
          </div>
        </div>

        <div class="more_box">
          <div class="more">
            <a class="more_entry" [routerLink]="['/betround']">Meine Tipprunden<span
              class="info">Eine Übersicht deiner Tipprunden zum Bearbeiten</span></a>
          </div>
        </div>

        <div class="more_box">
          <div class="more">
            <a class="more_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_rules', 'show');">Regeln<span
              class="info">Die Regeln von tippfreunde</span></a>
          </div>
        </div>

        <div class="more_box">
          <div class="more">
            <a class="more_entry" [routerLink]="['/profile']">Mein Profil<span
              class="info">Deine Kontodaten bearbeiten</span></a>
          </div>
        </div>

        <div class="bottom_line">
          <a class="bottom_line_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_contact', 'show');">Kontakt</a>
          <a class="bottom_line_devider">|</a>
          <a class="bottom_line_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_agb', 'show');">AGB</a>
          <a class="bottom_line_devider">|</a>
          <a class="bottom_line_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_impressum', 'show');">Impressum</a>
        </div>

        <span *ngIf="sharedService.loggedInUser.admin" class="headline">Administration</span>
        <div class="more_box">
          <div class="more">
            <a class="more_entry" [routerLink]="['/editResults']"
               *ngIf="sharedService.loggedInUser.admin">
              Ergebnisverwaltung</a>
          </div>
        </div>
        <div class="more_box">
          <div class="more">
            <a class="more_entry" [routerLink]="['/editLeagues']"
               *ngIf="sharedService.loggedInUser.admin">
              Ligaverwaltung</a>
          </div>
        </div>
        <div class="more_box">
          <div class="more">
            <a class="more_entry" [routerLink]="['/editBonusQuestions']"
               *ngIf="sharedService.loggedInUser.admin">
              Bonusfragen</a>
          </div>
        </div>
        <div class="more_box">
          <div class="more">
            <a class="more_entry" [routerLink]="['/statistik']" *ngIf="sharedService.loggedInUser.admin">
              Statistiken</a>
          </div>
        </div>
        <div class="more_box">
          <div class="more">
            <a class="more_entry" [routerLink]="['/newsletter']"
               *ngIf="sharedService.loggedInUser.admin">
              Newsletter</a>
          </div>
        </div>
        <div class="more_box">
          <div class="more">
            <a id="adminSynch" class="more_entry link" (click)="actualizeGames()"
               *ngIf="sharedService.loggedInUser.admin">
              Synchronisation</a>
          </div>
        </div>


      </div>
    </div>
  </div>

</div>

