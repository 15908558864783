<!-- BEGINN TIPPSTATISTIK OVERLAY -->
<div id="overlay_bonusbetstatistik_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_bonusbetstatistik" class="overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">TIPPSTATISTIK</span>
    <div class="overlay_ajax_loader">
      <img id="bonusstatistik_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">{{bonusBetStatistik?.betRoundLeagueBonusQuestion.bonusQuestion.questionText}}</span>
  </div>
  <div class="overlay_box_content">
    <div class="content_container">

      <div class="headline">{{bonusBetStatistik?.userBets?.length}} Tipps abgegeben;
        {{bonusBetStatistik?.usersWithNoUserBet?.length}}
        ausstehend
      </div>
      <div class="statistik_box" *ngIf="bonusBetStatistik?.groupedUserBonusQuestionBets.length > 0">
        <div *ngFor="let userBetContainer of bonusBetStatistik?.groupedUserBonusQuestionBets">
          <div class="result_box">
            <div class="statistik_content_box statistik_content_box_grey">
            <span
              class="result">{{userBetContainer.answer}}
              <span
                class="result_count">wird von {{userBetContainer?.userBets?.length}} Spielern getippt</span>
            </span>
              <span class="statistik_text">
                <span class="user_image" *ngFor="let userBet of userBetContainer.userBets">
                  <img class="user_bet_img" (click)="showToast(userBet.user.formattedName);" alt="Nutzerbild"
                       [ngClass]="{'fixed_user_bet_img': userBet.fixedBet}"
                       src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=40&amp;userId={{userBet.user.id}}&amp;randomImageId={{sharedService.randomImageId}}">
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="headline" *ngIf="bonusBetStatistik?.usersWithNoUserBet.length > 0">Kein Tipp abgegeben</div>
      <div class="statistik_box" *ngIf="bonusBetStatistik?.usersWithNoUserBet.length > 0">
        <div class="result_box">
          <div class="statistik_content_box statistik_content_box_grey">
            <span class="statistik_text" style="padding-top: 10px;">
              <span class="user_image" *ngFor="let user of bonusBetStatistik.usersWithNoUserBet">
              <img class="user_bet_img" (click)="showToast(user.formattedName);" alt="Nutzerbild"
                   src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=40&amp;userId={{user.id}}&amp;randomUserId=0.6914100716083038">
            </span>
          </span>
          </div>
        </div>
      </div>

      <span class="info">Spieler mit orangenem Rahmen haben feste Tipps!</span>
    </div>
  </div>
</div>
<div class="toast" style="display:none">{{toastUser}}</div>
<!-- ENDE TIPPSTATISTIK OVERLAY -->
