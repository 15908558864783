<div class="menu">
  <div class="menu_content">
    <div class="menu_break_headline menu_break_headline_split link" [routerLink]="['/changebetround']"
         *ngIf="sharedService.currentBetRoundId">
      <span class="menu_entry_text_split">
        <span class="menu_entry_text_left">
          {{sharedService.currentBetRoundLeague?.betRound.name}}
          <span class="menu_entry_text_left_second">{{sharedService.currentBetRoundLeague?.league.name}}</span>
        </span>
        <span class="menu_entry_text_right">
          <img style="border-radius: 25px; vertical-align: bottom" alt="Tipprundenbild"
               src="https://www.tippfreun.de/LoadBetRoundPhotoServlet?scaleSize=30&amp;betRoundId={{sharedService.currentBetRoundLeague?.betRound.id}}&amp;randomImageId={{sharedService.randomImageId}}">
        </span>
      </span>
    </div>
    <a id="bet" class="menu_entry" [routerLink]="['/matchday']" [style.display]="!betRoundAvailable ? 'none' : ''">
      <span class="menu_entry_text">Tippen</span></a>
    <a id="ranking" class="menu_entry" [routerLink]="['/ranking']"
       [style.display]="!betRoundAvailable ? 'none' : ''"><span
      class="menu_entry_text">Ranking</span></a>
    <a id="bonus" class="menu_entry" [routerLink]="['/bonus']" [style.display]="!betRoundAvailable ? 'none' : ''"><span
      class="menu_entry_text">Bonustipps</span></a>
    <a id="comments" class="menu_entry" [routerLink]="['/comments']" [style.display]="!betRoundAvailable ? 'none' : ''"><span
      class="menu_entry_text">Kommentare</span></a>

    <div class="menu_break_headline"><span class="menu_entry_text">Tipprunden</span></div>
    <a class="menu_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_newbetround', 'show');">
      <span class="menu_entry_text">Neue Tipprunde</span></a>
    <a id="betround" class="menu_entry" [routerLink]="['/betround']">
      <span class="menu_entry_text">Meine Tipprunden</span></a>
    <a id="changeBetround" class="menu_entry" [routerLink]="['/changebetround']">
      <span class="menu_entry_text">Tipprunde wechseln</span></a>
    <a id="rules" class="menu_entry link" (click)="sharedJqueryService.openCloseOverlay('overlay_rules', 'show');">
      <span class="menu_entry_text">Regeln</span></a>

    <div class="menu_break_headline menu_break_headline_split">
      <span class="menu_entry_text_split">
        <span class="menu_entry_text_left">
          {{sharedService.loggedInUser?.formattedName}}
        </span>
        <span class="menu_entry_text_right">
          <img style="border-radius: 25px; vertical-align: bottom"
               src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=30&amp;userId={{sharedService.loggedInUser.id}}&amp;randomImageId={{sharedService.randomImageId}}">
        </span>
      </span>
    </div>
    <a id="profile" class="menu_entry" [routerLink]="['/profile']">
      <span class="menu_entry_text">Einstellungen</span></a>

    <div class="menu_break_headline" [hidden]="!admin"><span class="menu_entry_text">Administration</span>
    </div>
    <div id="editResults" class="menu_entry" [routerLink]="['/editResults']" *ngIf="admin">
      <span class="menu_entry_text" [hidden]="!admin">Ergebnisverwaltung</span></div>
    <div id="editLeagues" class="menu_entry" [routerLink]="['/editLeagues']" *ngIf="admin">
      <span class="menu_entry_text" [hidden]="!admin">Ligenverwaltung</span></div>
    <div id="editBonusQuestions" class="menu_entry" [routerLink]="['/editBonusQuestions']"
         *ngIf="admin">
      <span class="menu_entry_text" [hidden]="!admin">Bonusfragen</span></div>
    <div id="statistik" class="menu_entry" [routerLink]="['/statistik']" *ngIf="admin">
      <span class="menu_entry_text" [hidden]="!admin">Statistiken</span></div>
    <div id="newsletter" class="menu_entry" [routerLink]="['/newsletter']" *ngIf="admin">
      <span class="menu_entry_text" [hidden]="!admin">Newsletter</span></div>
    <div id="adminSynch" class="menu_entry link" (click)="actualizeGames()" *ngIf="admin">
      <span class="menu_entry_text" [hidden]="!admin">Synchronisation</span></div>

    <div class="bottom_line">
      <a class="bottom_line_entry"
         (click)="sharedJqueryService.openCloseOverlay('overlay_contact', 'show');">Kontakt</a>
      <a class="bottom_line_devider">|</a>
      <a class="bottom_line_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_agb', 'show');">AGB & Datenschutz</a>
      <a class="bottom_line_devider">|</a>
      <a class="bottom_line_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_impressum', 'show');">Impressum</a>
    </div>

  </div>
</div>

<div class="mobile_menu">
  <a id="bet_mobile" class="mobile_menu_entry" [routerLink]="['/matchday']">
    <img id="bet_mobile_black" src="assets/mobilemenu/bet.png" class="mobile_menu_icon" alt="Tippen"/>
    <img id="bet_mobile_white" src="assets/mobilemenu/bet_white.png" class="mobile_menu_icon" alt="Tippen"/>
  </a>
  <a id="ranking_mobile" class="mobile_menu_entry" [routerLink]="['/ranking']">
    <img id="ranking_mobile_black" src="assets/mobilemenu/ranking.png" class="mobile_menu_icon" alt="Ranking"/>
    <img id="ranking_mobile_white" src="assets/mobilemenu/ranking_white.png" class="mobile_menu_icon" alt="Ranking"/>
  </a>
  <a id="bonus_mobile" class="mobile_menu_entry" [routerLink]="['/bonus']">
    <img id="bonus_mobile_black" src="assets/mobilemenu/bonus.png" class="mobile_menu_icon" alt="Bonustipps"/>
    <img id="bonus_mobile_white" src="assets/mobilemenu/bonus_white.png" class="mobile_menu_icon" alt="Bonustipps"/>
  </a>
  <a id="comments_mobile" class="mobile_menu_entry" [routerLink]="['/comments']">
    <img id="comments_mobile_black" src="assets/mobilemenu/comments.png" class="mobile_menu_icon" alt="Kommentare"/>
    <img id="comments_mobile_white" src="assets/mobilemenu/comments_white.png" class="mobile_menu_icon" alt="Kommentare"/>
  </a>
  <a id="more_mobile" class="mobile_menu_entry" [routerLink]="['/more']">
    <img id="more_mobile_black" src="assets/mobilemenu/more.png" class="mobile_menu_icon" alt="Mehr"/>
    <img id="more_mobile_white" src="assets/mobilemenu/more_white.png" class="mobile_menu_icon" alt="Mehr"/>
  </a>
</div>


<newbetround></newbetround>
<rules></rules>
<contact></contact>
<impressum></impressum>
<agb></agb>
