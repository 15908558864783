<!-- BEGINN SPIELSTATISTIK OVERLAY -->
<div id="overlay_gamestatistik_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays(); close();"></div>
<div id="overlay_gamestatistik" class="overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">SPIELSTATISTIK</span>
    <div class="overlay_ajax_loader">
      <img id="gamestatistik_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays(); close();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">{{game?.team1}} : {{game?.team2}}</span>
  </div>
  <div class="overlay_box_content">
    <div class="content_container">

      <div class="headline" *ngIf="table?.length > 0">Aktuelle Tabelle</div>
      <div class="table" *ngIf="table?.length > 0">
        <div class="table_header">
          <span class="table_place">Pl.</span>
          <span class="table_team_name_header">Name</span>
          <span class="table_games">Sp.</span>
          <span class="table_last_games"></span>
          <span class="table_goals">Tore</span>
          <span class="table_goals">Gegentore</span>
          <span class="table_goals">Tordifferenz</span>
          <span class="table_points">Pkt.</span>
        </div>
        <div class="table_entry" *ngFor="let team of table; let place = index;">
          <span class="table_place">{{place + 1}}.</span>
          <span class="table_team_name">{{team.teamName}}</span>
          <span class="table_games">{{team.games}}</span>
          <span class="table_last_games">
            <span *ngFor="let gameResult of team.lastFiveGames" class="last_game_{{gameResult}}">{{gameResult}}</span>
          </span>
          <span class="table_goals">{{team.goals}}</span>
          <span class="table_goals">{{team.goalsConceded}}</span>
          <span class="table_goals">{{team.goalsDiffernce}}</span>
          <span class="table_points">{{team.points}}</span>
        </div>
      </div>

      <div class="headline" *ngIf="gameStatistik?.directComparisonGamesStatistik">Statistik - Direkter Vergleich</div>
      <div class="statistik_box" *ngIf="gameStatistik?.directComparisonGamesStatistik">

        <div class="statistik_container">
          <div class="statistik">
            <div class="statistik_content_box statistik_content_box_green">
            <span
              class="statistik_percent">{{gameStatistik?.directComparisonGamesStatistik?.teamVicotriesInPercent}}%</span>
              <span class="statistik_text">{{game?.team1}}</span>
            </div>
          </div>
          <div class="statistik">
            <div class="statistik_content_box statistik_content_box_yellow">
            <span
              class="statistik_percent">{{gameStatistik?.directComparisonGamesStatistik?.teamDrawsInPercent}}%</span>
              <span class="statistik_text">Unentschieden</span>
            </div>
          </div>
          <div class="statistik">
            <div class="statistik_content_box statistik_content_box_red">
            <span
              class="statistik_percent">{{gameStatistik?.directComparisonGamesStatistik?.teamDefeatsInPercent}}%</span>
              <span class="statistik_text">{{game?.team2}}</span>
            </div>
          </div>
        </div>

        <span class="between_headline">Die letzten 3 Spiele</span>
        <div class="statistik_container">
          <div class="statistik" *ngFor="let lastGame of gameStatistik?.directComparisonGames">
            <div class="statistik_content_box statistik_content_box_grey">
              <span class="game_result">{{lastGame.goalsTeam1}}:{{lastGame.goalsTeam2}}</span>
              <span class="game_team_name">{{lastGame.team1}} <span
                class="game_team_devider">:</span>{{lastGame.team2}}</span>
              <span class="game_date">{{lastGame.formattedPlayDate}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="headline" *ngIf="gameStatistik?.team1Statistik">Statistik - {{game?.team1}}</div>
      <div class="statistik_box" *ngIf="gameStatistik?.team1Statistik">

        <div class="statistik_container">
          <div class="statistik">
            <div class="statistik_content_box statistik_content_box_green">
            <span
              class="statistik_percent">{{gameStatistik?.team1Statistik?.teamVicotriesInPercent}}%</span>
              <span class="statistik_text">gewonnen</span>
            </div>
          </div>
          <div class="statistik">
            <div class="statistik_content_box statistik_content_box_yellow">
            <span
              class="statistik_percent">{{gameStatistik?.team1Statistik?.teamDrawsInPercent}}%</span>
              <span class="statistik_text">unentschieden</span>
            </div>
          </div>
          <div class="statistik">
            <div class="statistik_content_box statistik_content_box_red">
            <span
              class="statistik_percent">{{gameStatistik?.team1Statistik?.teamDefeatsInPercent}}%</span>
              <span class="statistik_text">verloren</span>
            </div>
          </div>
        </div>

        <span class="between_headline">Die letzten 3 Spiele</span>
        <div class="statistik_container">
          <div class="statistik" *ngFor="let lastGame of gameStatistik?.lastGamesTeam1">
            <div class="statistik_content_box statistik_content_box_grey">
              <span class="game_result">{{lastGame.goalsTeam1}}:{{lastGame.goalsTeam2}}</span>
              <span class="game_team_name">{{lastGame.team1}} <span
                class="game_team_devider">:</span>{{lastGame.team2}}</span>
              <span class="game_date">{{lastGame.formattedPlayDate}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="headline" *ngIf="gameStatistik?.team2Statistik">Statistik - {{game?.team2}}</div>
      <div class="statistik_box" *ngIf="gameStatistik?.team2Statistik">
        <div class="statistik_container">
          <div class="statistik">
            <div class="statistik_content_box statistik_content_box_green">
            <span
              class="statistik_percent">{{gameStatistik?.team2Statistik?.teamVicotriesInPercent}}%</span>
              <span class="statistik_text">gewonnen</span>
            </div>
          </div>
          <div class="statistik">
            <div class="statistik_content_box statistik_content_box_yellow">
            <span
              class="statistik_percent">{{gameStatistik?.team2Statistik?.teamDrawsInPercent}}%</span>
              <span class="statistik_text">unentschieden</span>
            </div>
          </div>
          <div class="statistik">
            <div class="statistik_content_box statistik_content_box_red">
            <span
              class="statistik_percent">{{gameStatistik?.team2Statistik?.teamDefeatsInPercent}}%</span>
              <span class="statistik_text">verloren</span>
            </div>
          </div>
        </div>
        <span class="between_headline">Die letzten 3 Spiele</span>
        <div class="statistik_container">
          <div class="statistik" *ngFor="let lastGame of gameStatistik?.lastGamesTeam2">
            <div class="statistik_content_box statistik_content_box_grey">
              <span class="game_result">{{lastGame.goalsTeam1}}:{{lastGame.goalsTeam2}}</span>
              <span class="game_team_name">{{lastGame.team1}} <span
                class="game_team_devider">:</span>{{lastGame.team2}}</span>
              <span class="game_date">{{lastGame.formattedPlayDate}}</span>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
<!-- ENDE SPIELSTATISTIK OVERLAY -->
