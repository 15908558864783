<header></header>

<menu [active]="'bet'"></menu>

<div class="content">
  <div class="matchday_container" (swipeleft)="nextMatchday()" (swiperight)="previousMatchday()">

    <div class="matchday_slider" *ngIf="matchdayGameContainer">
      <span (click)="previousMatchday()" class="matchday_slider_arrow"><img src="assets/matchday/left.png" alt="Pfeil nach Links"/></span>
      <span class="matchday_slider_label">{{sharedService.currentMatchDay}}</span>
      <span (click)="nextMatchday()" class="matchday_slider_arrow"><img src="assets/matchday/right.png" alt="Pfeil nach Rechts"/></span>
    </div>

    <div class="matchday" *ngFor="let matchDayGames of matchdayGameContainer; let matchDayGamesIndex = index;">
      <span class="matchday_date">{{matchDayGames.gamesWithUserBets[0].game.formattedPlayDateWithDay}}</span>
      <span class="reload_button"
            (click)="sharedJqueryService.showAjaxLoader('reload_ajax_loader'); loadGamesWithUserBet();"
            *ngIf="matchDayGamesIndex == 0"><img
        src="assets/matchday/reload.png" alt="Seite aktualisieren"/></span>
      <span id="reload_ajax_loader" class="reload_ajax_loader" *ngIf="matchDayGamesIndex == 0"><img
        alt="Bitte warten..."
        src="assets/matchday/reload_ajax_loader.gif"/></span>
      <div class="games_container">
        <div class="game_box">
          <div class="game"
               *ngFor="let gameWithUserBet of matchDayGames.gamesWithUserBets; let gameWithUserBetIndex = index;">
            <span class="time">{{gameWithUserBet.game.formattedPlayTime}}</span>
            <span class="teams"><span class="team">{{gameWithUserBet.game.team1}}</span> <span
              class="team_devider">:</span><span
              class="team">{{gameWithUserBet.game.team2}}</span></span>
            <span class="teamresult" *ngIf="gameWithUserBet.game.finished">{{gameWithUserBet.game.goalsTeam1}}<span
              class="bet_devider">:</span>{{gameWithUserBet.game.goalsTeam2}}</span>
            <span class="teamresult"
                  *ngIf="gameWithUserBet.game.today && !gameWithUserBet.game.live && gameWithUserBet.game.betable"><img
              src="assets/matchday/time.png" alt="Uhr"/><span
              class="countdown">{{gameWithUserBet.game.countdown}}</span></span>
            <span class="teamresult liveresult"
                  *ngIf="gameWithUserBet.game.live">{{gameWithUserBet.game.goalsTeam1}}<span
              class="bet_devider">:</span>{{gameWithUserBet.game.goalsTeam2}}</span>
            <span class="teamresult"
                  *ngIf="!gameWithUserBet.game.live && !gameWithUserBet.game.today && !gameWithUserBet.game.finished"><img
              src="assets/matchday/date.png" alt="Kalender"/>
              <span
                class="countdown">{{gameWithUserBet.game.countdown}}</span>
            </span>
            <span class="teambet" *ngIf="!gameWithUserBet.game.betable || gameWithUserBet.userBet.fixedBet">
              <span *ngIf="gameWithUserBet.userBet.goalsTeam1 != null">
                {{gameWithUserBet.userBet.goalsTeam1}}
                <span class="bet_devider">:</span>
                {{gameWithUserBet.userBet.goalsTeam2}}
              </span>
              <span *ngIf="gameWithUserBet.userBet.goalsTeam1 == null">
                Kein Tipp
              </span>
            </span>
            <span class="teambet" *ngIf="gameWithUserBet.game.betable && !gameWithUserBet.userBet.fixedBet">
              <input class="bet" type="number" pattern="[0-9]*" inputmode="numeric"
                     [(ngModel)]="gameWithUserBet.userBet.goalsTeam1" (keyup.enter)="jumpToNextInput($event)"
                     (keyup)="saveUserBet(gameWithUserBet.game.id, gameWithUserBet.userBet.goalsTeam1, gameWithUserBet.userBet.goalsTeam2, matchDayGamesIndex, gameWithUserBetIndex)"/>
              <span class="bet_devider">:</span>
              <input class="bet" type="number" pattern="[0-9]*" inputmode="numeric"
                     (keyup.enter)="jumpToNextInput($event)"
                     [(ngModel)]="gameWithUserBet.userBet.goalsTeam2"
                     (keyup)="saveUserBet(gameWithUserBet.game.id, gameWithUserBet.userBet.goalsTeam1, gameWithUserBet.userBet.goalsTeam2, matchDayGamesIndex, gameWithUserBetIndex)"/>
            </span>
            <span class="live" *ngIf="gameWithUserBet.game.live && gameWithUserBet.userBet?.points == null"><img
              src="assets/matchday/live.png" alt="Live"/></span>
            <span class="locked"
                  *ngIf="gameWithUserBet.userBet.fixedBet && !gameWithUserBet.game.live && gameWithUserBet.userBet?.points == null"><img
              src="assets/matchday/locked.png" alt="Gesperrt"/></span>
            <span class="unlocked"
                  *ngIf="gameWithUserBet.game.betable && !gameWithUserBet.userBet.fixedBet && !gameWithUserBet.game.live && gameWithUserBet.userBet?.points == null"><img
              src="assets/matchday/unlocked.png" alt="Freigegeben"/></span>
            <span
              [ngClass]="{'three_points': gameWithUserBet.userBet.points==3,'two_points': gameWithUserBet.userBet?.points==2, 'one_point': gameWithUserBet.userBet?.points==1, 'zero_points': gameWithUserBet.userBet?.points==0 }"
              *ngIf="gameWithUserBet.userBet?.points != null">
                {{gameWithUserBet.userBet?.points}} <span
              class="point_label" *ngIf="gameWithUserBet.userBet?.points != 1">Punkte</span><span
              class="point_label" *ngIf="gameWithUserBet.userBet?.points == 1">Punkt</span></span>
            <span class="zero_points"
                  *ngIf="gameWithUserBet.userBet?.points == null && !gameWithUserBet.game?.betable && !gameWithUserBet.game.live">
              0 <span class="point_label">Punkte</span>
            </span>
            <span id="bet_ajax_loader_{{gameWithUserBet.game.id}}" class="bet_ajax_loader">
              <img alt="Bitte warten..." src="assets/matchday/bet_ajax_loader.gif"/>
            </span>
            <a class="menu_link" (click)="sharedJqueryService.openCloseMenu(gameWithUserBet.game.id);">
              <img class="more_menu" src="assets/matchday/more_menu.png" alt="Menü"/>
              <img class="more_menu" src="assets/matchday/more_menu_hover.png" alt="Menü"/>
            </a>
            <div id="menu_{{gameWithUserBet.game.id}}" class="menu">
              <a class="menu_entry"
                 (click)="openGameStatistik(gameWithUserBet.game);"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/matchday/chart.png" alt="Spielstatistik"/>
								</span> <span class="menu_entry_text"> SPIELSTATISTIK </span>
              </a>
              <a class="menu_entry" *ngIf="gameWithUserBet?.userBet?.id"
                 (click)="openBetStatistik(gameWithUserBet);"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/matchday/save.png" alt="Speichern - Tippstatistik"/>
								</span> <span class="menu_entry_text"> TIPPSTATISTIK </span>
              </a>
              <!--
              <a class="menu_entry"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/ranking/joker.png"/>
								</span> <span class="menu_entry_text"> JOKER </span>
              </a>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- SPIEL STATISTIK OVERLAY -->
<gamestatistik [game]="selectedGame" (closed)="clearSelectedGame()"></gamestatistik>

<!-- TIPP STATISTIK OVERLAY -->
<betstatistik [game]="selectedGameForBet" (closed)="clearSelectedGame()"></betstatistik>

<!-- DATENSCHUTZERKLÄRUNG OVERLAY -->
<dataPrivacyOverlay></dataPrivacyOverlay>

