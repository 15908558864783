<header></header>

<menu [active]="'betround'"></menu>

<div class="content">
  <div class="content_container">
    <div class="betrounds">
      <div class="betround_container">
        <div class="betround_box"
             *ngFor="let betRound of betRounds; let betroundIndex = index;">
          <div class="betround">
            <span class="bet_round_img"> <img style="border-radius: 25px; vertical-align: bottom" alt="Tipprundenbild"
                                              src="https://www.tippfreun.de/LoadBetRoundPhotoServlet?scaleSize=40&amp;betRoundId={{betRound?.id}}&amp;randomImageId={{sharedService.randomImageId}}"></span>
            <span class="bet_round_name">{{betRound.name}}
              <span class="League_name">{{betRound.leagues.length}} Wettbewerbe</span></span>
            <a class="menu_link" (click)="sharedJqueryService.openCloseMenu(betRound.id);">
              <img class="more_menu" src="assets/matchday/more_menu.png" alt="Menü"/>
              <img class="more_menu" src="assets/matchday/more_menu_hover.png" alt="Menü"/>
            </a>
            <div id="menu_{{betRound.id}}" class="menu">
              <a class="menu_entry"
                 [routerLink]="['/betrounddetails', betRound.id]"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/betround/edit.png" alt="Bearbeiten"/>
								</span>
                <span class="menu_entry_text" *ngIf="betRound.admin"> BEARBEITEN </span>
                <span class="menu_entry_text" *ngIf="!betRound.admin"> ANZEIGEN </span>
              </a>
              <a class="menu_entry" (click)="setDeleteBetRoundId(betRound.id); sharedJqueryService.openCloseOverlay('overlay_left_betround', 'show');"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/betround/delete.png" alt="Verlassen"/>
								</span> <span class="menu_entry_text"> VERLASSEN </span>
              </a>
              <a class="menu_entry" *ngIf="betRound.admin" (click)="setDeleteBetRoundId(betRound.id); sharedJqueryService.openCloseOverlay('overlay_delete_betround', 'show');"> <span
                class="menu_entry_icon">
									<img class="menu_entry_icon" src="assets/betround/delete_forever.png" alt="Löschen"/>
								</span> <span class="menu_entry_text"> LÖSCHEN </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="betrounds" *ngIf="betRounds?.length == 0">
      <div class="betround_container">
        <span class="headline no_content">Du hast noch keine Tipprunden</span>
      </div>
    </div>


  </div>
</div>

<!-- BEGINN VERLASSEN OVERLAY -->
<div id="overlay_left_betround_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_left_betround" class="overlay_box" style="bottom: auto;">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">TIPPRUNDE VERLASSEN</span>
    <div class="overlay_ajax_loader">
      <img id="left_betround_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">Tipprunde wirklich verlassen?</span>
  </div>
  <div class="overlay_box_content">
    <form class="overlay_form" (ngSubmit)="leftBetRound(); sharedJqueryService.showAjaxLoader('login_ajax_loader');">
      <div class="standard_input_button" style="padding-right: 5px;">
        <input type="reset" value="ABBRECHEN"
               (click)="sharedJqueryService.closeAllOverlays();"/>
        <input type="submit" class="delete_button" value="VERLASSEN"/>
      </div>
    </form>
  </div>
</div>
<!-- ENDE VERLASSEN OVERLAY -->

<!-- BEGINN DELETE OVERLAY -->
<div id="overlay_delete_betround_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_delete_betround" class="overlay_box" style="bottom: auto;">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">TIPPRUNDE LÖSCHEN</span>
    <div class="overlay_ajax_loader">
      <img id="deletebetround_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">Tipprunde wirklich löschen?</span>
  </div>
  <div class="overlay_box_content">
    <form class="overlay_form" (ngSubmit)="deleteBetRound(); sharedJqueryService.showAjaxLoader('login_ajax_loader');">
      <div class="standard_input_button" style="padding-right: 5px;">
        <input type="reset" value="ABBRECHEN"
               (click)="sharedJqueryService.closeAllOverlays();"/>
        <input type="submit" class="delete_button" value="LÖSCHEN"/>
      </div>
    </form>
  </div>
</div>
<!-- ENDE DELETE OVERLAY -->

<!-- WILLKOMMEN OVERLAY -->
<welcome></welcome>

<!-- DATENSCHUTZERKLÄRUNG OVERLAY -->
<dataPrivacyOverlay></dataPrivacyOverlay>
