<!-- BEGINN NEUE TIPPRUNDE OVERLAY -->
<div id="overlay_newbetround_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_newbetround" class="overlay_box overlay_box_auto_height">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">NEUE TIPPRUNDE</span>
    <div class="overlay_ajax_loader">
      <img id="newbetround_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">
      Gründe eine neue Tipprunde mit deinen Freunden
    </span>
  </div>
  <div class="overlay_box_content">

    <form class="overlay_form"
          (ngSubmit)="sharedJqueryService.showAjaxLoader('newbetround_ajax_loader'); createNewBetRound(); ">
      <div class="standard_input_text">
        <label class="input_text_label input_text_label_down">Name</label>
        <input type="text" [(ngModel)]="betRound.name"
               [ngModelOptions]="{standalone: true}" required/>
      </div>
      <div class="standard_input_text">
        <label class="input_text_label input_text_label_down">Beschreibung</label>
        <textarea [(ngModel)]="betRound.description" [ngModelOptions]="{standalone: true}" required></textarea>
      </div>
      <div class="standard_input_text">
        <label class="input_text_label">Wettbewerb</label>
        <select class="select_answer_input" [(ngModel)]="selectedLeagueId" [ngModelOptions]="{standalone: true}">
          <option *ngFor="let league of availableLeagues"
                  [value]="league.id">
            {{league.name}} {{league.saison}}
          </option>
        </select>
      </div>
      <div class="standard_input_button" style="padding-right: 5px;">
        <input type="button" class="reset_button" value="ABBRECHEN"
               (click)="sharedJqueryService.closeAllOverlays();"/>
        <input type="submit" value="SPEICHERN"/>
      </div>
    </form>

  </div>
</div>
<!-- ENDE NEUE TIPPRUNDE OVERLAY -->
