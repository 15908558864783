<div class="rules_box">
  <div class="rule">
    <div class="headline">Datenschutzerklärung</div>
    <p>Mit dieser Datenschutzerklärung möchten wir Sie über Art,
      Umfang und Zweck der Verarbeitung von personenbezogenen Daten auf
      unserer Webseite aufklären. Personenbezogene Daten sind alle Daten,
      die einen persönlichen Bezug zu Ihnen aufweisen, z. B. Name, Adresse,
      E-Mail-Adresse oder Nutzerverhalten.</p>
    <span class="sub_headline">
      <strong>Wer bei uns für die Datenverarbeitung verantwortlich
        ist</strong>
    </span>
    <p>Verantwortlich für die Datenverarbeitung ist:</p>
    <p>
      René Riegler<br>Homburger Landstraße 94<br>60435 Frankfurt<br>Deutschland<br>info@tippfreun.de<br>
    </p>
    <p>
      Florian Dittmann<br>Am Ebelfeld 230<br>60488 Frankfurt<br>Deutschland<br>info@tippfreun.de<br>
    </p>
    <span class="sub_headline">Webhosting</span>
    <p>Wir bedienen uns zum Vorhalten unserer Onlinepräsenz eines
      Internet-Service-Providers, auf dessen Server die Webseite gespeichert
      wird (Hosting) und der unsere Seite im Internet verfügbar macht.
      Hierbei verarbeitet der Internet-Service-Providers in unserem Auftrag
      Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten,
      Bestandsdaten sowie Meta- und Kommunikationsdaten. Rechtsgrundlage:
      Rechtsgrundlage für die vorbeschriebene Verarbeitung ist unser
      berechtigtes Interesse an einer effizienten und sicheren
      Zurverfügungstellung unseres Onlineangebotes, Art. 6 Abs. 1 lit. f
      DSGVO i. V. m. Art. 28 DSGVO (Auftragsverarbeitungsvertrag).</p>
    <p></p>
    <div class="ce_text block">
      <p>Sofern Sie unsere Webseite lediglich informatorisch nutzen,
        werden von unserem Internet-Service-Provider nur diejenigen
        personenbezogenen Daten erhoben, die der von Ihnen verwendete Browser
        an dessen Server übermittelt. Das sind folgende Daten:</p>
      <ul>
        <li>IP-Adresse</li>
        <li>das Datum und die Uhrzeit des Zugriffs auf unsere
          Internetseite
        </li>
        <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
        <li>Zugriffsstatus (HTTP-Status)</li>
        <li>die übertragene Datenmenge</li>
        <li>der Internet-Service-Provider des zugreifenden Systems</li>
        <li>der von Ihnen verwendete Browsertyp und dessen Version</li>
        <li>das von Ihnen verwendete Betriebssystem</li>
        <li>die Internetseite, von welcher Sie gegebenenfalls auf unsere
          Internetseite gelangt sind
        </li>
        <li>die Seiten bzw. Unterseiten, welche Sie auf unserer
          Internetseite besuchen.
        </li>
      </ul>
      <p>
        Die vorgenannten Daten werden als Logfiles auf den Servern unseres
        Internet-Service-Providers gespeichert. Dies ist erforderlich, um die
        Webseite auf dem von Ihnen genutzten Endgerät darstellen zu können,
        sowie die Stabilität und Sicherheit zu gewährleisten. In den
        vorstehenden Zwecken liegt unser berechtigtes Interesse an der
        Datenverarbeitung. <strong>Rechtsgrundlage:</strong> Rechtsgrundlage
        hierfür ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. <strong>Dauer:</strong>
        Die vorstehenden Daten zur Bereitstellung unserer Webseite werden
        für die Dauer von 7 Tagen gespeichert und dann gelöscht. <strong>Verhinderung:</strong>
        Da die Verarbeitung der vorstehenden Daten für die Bereitstellung
        unseres Internetauftritts zwingend erforderlich ist, besteht kein
        Widerspruchsrecht.
      </p>
    </div>
    <p></p>
    <span class="sub_headline">Cookies</span>
    <p>Unsere Internetseite nutzt Cookies. Bei Cookies handelt es sich
      um kleine Textdateien, bestehend aus einer Reihe von Zahlen und
      Buchstaben, die auf dem von Ihnen genutzten Endgerät abgelegt und
      gespeichert werden. Cookies übertragen weder Viren noch können sie
      Programme ausführen. Vielmehr dienen sie vorrangig dazu,
      Informationen zwischen dem von Ihnen genutzten Endgerät und unserer
      Webseite auszutauschen, um unser Internetangebot für Sie
      nutzerfreundlicher und effektiver zu machen. Dabei ist zu
      unterscheiden zwischen temporären (transienten) Cookies uns
      persistenten Cookies. Zu den transienten Cookies zählen insbesondere
      die Session-Cookies. Diese speichern eine sogenannte Session-ID, mit
      welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen
      Sitzung zuordnen lassen. Dadurch erkennt unsere Webseite Ihren Rechner
      wieder, wenn Sie auf unsere Webseite zurückkehren. Die
      Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder Ihren
      Browser schließen. Persistente Cookies werden automatisiert nach
      einer vorgegebenen Dauer gelöscht, die sich je nach Cookie
      unterscheiden kann. Sie haben die Möglichkeit, diese Cookies in den
      Sicherheitseinstellungen Ihres Browsers jederzeit zu löschen.</p>
    <p>Die Cookies dienen dazu, unsere Website nutzerfreundlicher zu
      gestalten. Einige Elemente unserer Internetseite erfordern es, dass
      der aufrufende Browser auch nach einem Seitenwechsel identifiziert
      werden kann. In diesen Cookies können insbesondere Informationen zu
      Spracheinstellungen oder Log-In-Informationen gespeichert sein.</p>
    <p></p>
    <p>In den vorstehenden Zwecken liegt unser berechtigtes Interesse
      an der Datenverarbeitung. Rechtsgrundlage hierfür ist Art. 6 Abs. 1
      S. 1 lit. f DSGVO.</p>
    <p>Da die Cookies auf Ihrem Computer gespeichert werden, haben Sie
      als Nutzer auch die volle Kontrolle über die Verwendung von Cookies.
      Sie haben die Möglichkeit, über die Sicherheitseinstellungen Ihres
      Browsers festzulegen, ob Cookies überhaupt gespeichert werden. Sie
      können etwa von vornherein keine oder nur auf Nachfrage Cookies
      akzeptieren oder aber festlegen, dass Cookies nach jedem Schließen
      Ihres Browsers gelöscht werden. Werden Cookies für unsere Website
      deaktiviert, können möglicherweise nicht mehr alle Funktionen der
      Website vollumfänglich genutzt werden.</p>
    <span class="sub_headline">
      <strong>Speicherdauer/Löschung</strong>
    </span>
    <p>Wir löschen oder sperren Ihre personenbezogenen Daten, sobald
      der Zweck der Speicherung erreicht ist oder entfällt. Eine
      darüberhinausgehende Speicherung erfolgt nur, wenn uns dies durch
      nationale oder europäische Vorschriften aufgegeben wird. Eine
      Sperrung oder Löschung der Daten erfolgt in diesem Fall dann, wenn
      die in den jeweiligen Vorschriften vorgeschriebene Speicherfrist
      abgelaufen ist, es sei denn wir benötigen Ihre Daten zur Erfüllung
      eines zwischen uns geschlossenen Vertrages oder wenn dies zur
      Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
      erforderlich ist.</p>
    <span class="sub_headline">Kontaktaufnahme über Kontaktformular, E-Mail, Soziale Medien
      oder Telefon</span>
    <p>
      Soweit Sie das auf unserer Internetpräsenz angebotene Kontaktformular
      nutzen oder uns per E-Mail. Telefon, oder sozialer Medien ansprechen
      und uns hierüber personenbezogene Daten zur Verfügung stellen,
      werden diese Daten zur Verarbeitung Ihrer Anfrage in unserem Hause
      automatisch gespeichert und weiterverarbeitet. Diese Daten werden nur
      zur Korrespondenz mit Ihnen verarbeitet. Die Datenübermittlung über
      unser Kontaktformular erfolgt über eine verschlüsselte
      SSL-Verbindung. Eine Weitergabe Ihrer Daten an Dritte erfolgt nicht. <strong>Zweck:</strong>
      Ihren Namen benötigen wir, um Sie in unserer Antwort ansprechen zu
      können. Ihre E-Mail-Adresse benötigen wir, um Ihre Anfrage
      beantworten zu können. Ihre Telefonnummer benötigen wir, um Ihrer
      Rückrufbitte nachkommen zu können. <strong>Rechtsgrundlage:</strong>
      Die Rechtsgrundlage für die vorbeschriebene Nutzung Ihrer Daten ist
      Art. 6 Abs.1 lit.&nbsp;a DSGVO.&nbsp;<strong>Löschung:</strong> Die
      von uns erhobenen personenbezogenen Daten werden gelöscht, sofern sie
      nicht mehr erforderlich sind. Wir überprüfen die Erforderlichkeit
      alle 2 Jahre. Außerdem können Sie die Datenverarbeitung jederzeit
      widerrufen.
    </p>
    <span class="sub_headline">Registrierung</span>
    <p>
      Sie haben die Möglichkeit sich auf unserer Webseite zu registrieren.
      Hierzu ist die Angabe personenbezogener Daten notwendig, die sich aus
      der Eingabemaske ergeben. Diese Daten werden von uns gespeichert und
      verarbeitet und können jederzeit durch Sie geändert oder gelöscht
      werden. Diese Daten werden nicht an Dritte weitergegeben außer sie
      dienen der Vertragsabwicklung. Wenn Sie sich registrieren, speichern
      wir Ihre IP-Adresse sowie Datum und Uhrzeit der Registrierung, um bei
      einem eventuellen Missbrauch den Nutzer ermitteln zu können. Ihre
      IP-Adresse, das Datum und die Uhrzeit der Registrierung werden als
      Daten nicht an Dritte weitergegeben. <strong>Zweck:</strong> Die
      Registrierung ermöglicht es Ihnen, auf Inhalte unserer Webseite
      zugreifen zu können, die in einem nicht-öffentlichen Bereich liegen.
      <strong>Rechtsgrundlage:</strong> Die Nutzung Ihrer Daten stützen wir
      auf Art. 6 Abs. 1 lit. a DSGVO. <strong>Löschung:</strong> Die
      personenbezogenen Daten, sowie IP-Adresse Datum und Uhrzeit werden
      solange gespeichert, bis der Zweck hierfür entfällt. Die Löschung
      der Daten, die im Rahmen der Registrierung erhoben wurden, erfolgt,
      sobald das dazugehörige Konto auf unserer Webseite gelöscht oder
      geändert wird. <strong>Verhinderung:</strong> Sie können jederzeit
      Ihr Konto auf unserer Webseite löschen oder die Löschung jederzeit
      durch den oben genannten Verantwortlichen fordern.
    </p>
    <span class="sub_headline">Newsletter</span>
    <p>
      Wir versenden in regelmäßigen Abständen einen Newsletter, um unsere
      Kunden und Geschäftspartner und Interessenten über unsere Angebote
      und über hiermit im Zusammenhang stehende Neuigkeiten zu informieren.
      Sie haben die Möglichkeit, sich auf unserer Internetseite zu unserem
      Newsletter anmelden und im Rahmen der Anmeldung in den Empfang des
      Newsletters einzuwilligen. Wenn Sie sich zu unserem Newsletter
      anmelden, ist die Angabe Ihrer E-Mail-Adresse verpflichtend. Die
      E-Mail-Adresse speichern wir, um Ihnen den Newsletter zusenden zu
      können. Sobald eine Anmeldung zu unserem Newsletter erfolgt, erhalten
      Sie eine Bestätigungs-E-Mail an die bei der Anmeldung hinterlegten
      E-Mail-Adresse im sog. Double-Opt-In-Verfahren. Diese E-Mail enthält
      einen Link. Wenn Sie auf diesen Link klicken, bestätigen Sie, dass
      Sie&nbsp;den Newsletter empfangen möchten. Damit stellen wir sicher,
      dass Ihre E-Mail-Adresse bei der Anmeldung nicht von einem Dritten
      missbraucht wurde. Aus demselben Grunde speichern wir das Datum und
      die Uhrzeit der Anmeldung sowie die bei der Anmeldung Ihnen
      zugeordnete IP-Adresse. Die vorgenannten Daten geben wir nicht an
      Dritte weiter. <strong>Zweck:</strong> Die vorbeschriebenen Abläufe
      und Interessen stellen den Zweck der Speicherung der Daten dar. <strong>Rechtsgrundlage:</strong>
      Rechtsgrundlage für die Verarbeitung Ihrer Daten ist Art. 6 Abs. 1
      lit. a DSGVO. <strong>Löschung:</strong> Die Löschung der
      E-Mail-Adresse erfolgt entweder, wenn Sie 1 Monat nach Versand der
      Bestätigungs-E-Mail im Double-Opt-In-Verfahren den Bestätigungslink
      nicht angeklickt haben oder unmittelbar nachdem Sie sich von unserem
      Newsletter abgemeldet haben.<strong>&nbsp;Widerruf:</strong> Sie
      können Ihre Einwilligung in den Empfang des Newsletters jederzeit
      widerrufen und sich von dem Abonnement des Newsletters abmelden. Wir
      bieten folgende Möglichkeiten an, über die Sie den Widerruf
      erklären können:
    </p>
    <p></p>
    <div class="ce_text privacy_item newsletter_unsubscribe block">
      <ul>
        <li>Klick auf den in dem Newsletter hierfür vorgehaltenen Link</li>
      </ul>
    </div>
    <p></p>
    <p></p>
    <div class="ce_text privacy_item newsletter_unsubscribe block">
      <ul>
        <li>Formular auf unserer Webseite</li>
      </ul>
    </div>
    <p></p>
    <p></p>
    <p></p>
    <span class="sub_headline">Ihre Rechte als Nutzer unserer Onlinepräsenz nach der DSGVO</span>
    <p>Nach der DSGVO stehen Ihnen die nachfolgend aufgeführten Rechte
      zu, die Sie jederzeit bei dem in Ziffer 1. dieser
      Datenschutzerklärung genannten Verantwortlichen geltend machen
      können:</p>
    <ul>
      <li><strong>Recht auf Auskunft:</strong> Sie können nach Art. 15
        DSGVO eine Bestätigung darüber verlangen, ob und welche
        personenbezogenen Daten wir von Ihnen verarbeiten. Darüber hinaus
        können Sie von uns unentgeltlich Auskunft über die
        Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die
        Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt
        wurden oder werden, die geplante Speicherdauer, das Bestehen eines
        Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung
        oder Widerspruch, das Bestehen eines Beschwerderechts sowie die
        Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden,
        verlangen. Ferner steht Ihnen ein Auskunftsrecht darüber zu, ob Ihre
        personenbezogenen Daten an ein Drittland oder an eine internationale
        Organisation übermittelt wurden. Sofern dies der Fall ist, steht
        Ihnen das Recht zu, Auskunft über die geeigneten Garantien im
        Zusammenhang mit der Übermittlung zu erhalten.
      </li>
      <li><strong>Recht auf Berichtigung:</strong> Gemäß Art. 16
        DSGVO können Sie die Berichtigung unrichtiger oder
        Vervollständigung unvollständiger der bei uns gespeicherten und Sie
        betreffenden personenbezogenen Daten verlangen.
      </li>
      <li><strong>Recht auf Löschung:</strong> Gemäß Art. 17 DSGVO
        steht Ihnen das Recht zu, die Löschung Ihrer bei uns gespeicherten
        personenbezogenen Daten zu verlangen, soweit wir deren Verarbeitung
        nicht zu folgenden Zwecken benötigen:
        <ul>
          <li>zur Erfüllung einer rechtlichen Verpflichtung,</li>
          <li>zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen,
          </li>
          <li>zur Ausübung des Rechts auf freie Meinungsäußerung und
            Information oder
          </li>
          <li>aus Gründen der in Art. 17 Abs. 3 lit c und d DSGVO
            genannten Fälle des öffentlichen Interesses.
          </li>
        </ul>
      </li>
      <li><strong>Recht auf Einschränkung:</strong> Gemäß Art. 18
        DSGVO haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen, wenn
        <ul>
          <li>die Richtigkeit der Daten von Ihnen bestritten wird, und
            zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der
            personenbezogenen Daten zu überprüfen,
          </li>
          <li>die Verarbeitung Ihrer Daten unrechtmäßig ist, Sie aber
            deren Löschung ablehnen und stattdessen die Einschränkung der
            Nutzung der Daten verlangen,
          </li>
          <li>wir die personenbezogenen Daten für die Zwecke der
            Verarbeitung nicht länger benötigen, Sie die Daten jedoch zur
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
            benötigen
          </li>
          <li>Sie gemäß Art. 21 DSGVO Widerspruch gegen die
            Verarbeitung Ihrer Daten eingelegt haben, es aber noch nicht
            feststeht, ob die berechtigten Gründe, die uns trotz Ihres
            Widerspruches zu einer weiteren Verarbeitung berechtigten, Ihre
            Rechte überwiegen.
          </li>
        </ul>
      </li>
      <li><strong>Recht auf Unterrichtung:</strong> Sofern Sie das
        Recht auf Berichtigung, Löschung oder Einschränkung der
        Verarbeitung gegenüber uns geltend gemacht haben, sind wir
        verpflichtet, allen Empfängern, denen gegenüber die Sie
        betreffenden personenbezogene Daten offengelegt wurden, die von Ihnen
        verlangte Berichtigung oder Löschung der Daten oder deren
        Einschränkung der Verarbeitung mitzuteilen, es sei denn, dies
        erweist sich als unmöglich oder ist mit einem unverhältnismäßigen
        Aufwand verbunden. Ihnen steht das Recht zu, von uns über diese
        Empfänger unterrichtet zu werden.
      </li>
      <li><strong>Recht auf Datenübertragbarkeit:</strong> Gemäß
        Art. 20 DSGVO können Sie verlangen, dass wir die Sie betreffenden
        personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem
        strukturierten, gängigen und maschinenlesebaren Format zu erhalten
        oder die Übermittlung an einen anderen Verantwortlichen zu
        verlangen.
      </li>
      <li><strong>Beschwerderecht</strong>: Nach Art. 77 DSGVO haben
        Sie das Recht, sich bei einer Aufsichtsbehörde zu beschweren.
        Hierfür können Sie sich an die Aufsichtsbehörde Ihres üblichen
        Aufenthaltsortes, Ihres Arbeitsplatzes oder unseres Firmensitzes
        wenden.
      </li>
    </ul>
    <span class="sub_headline">Widerrufsrecht</span>
    <p>Gemäß Art. 7 Abs. 3 DSGVO steht Ihnen das Recht zu, Ihre
      erteilte Einwilligung in die Verarbeitung Ihrer Daten jederzeit uns
      gegenüber zu widerrufen. Der von Ihnen erklärte Widerruf ändert
      nichts an der Rechtmäßigkeit der bis zum Widerruf erfolgten
      Verarbeitung Ihrer personenbezogenen Daten.</p>
    <span class="sub_headline">Widerspruchsrecht</span>
    <p>Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
      Situation ergeben, jederzeit gegen die Verarbeitung der Sie
      betreffenden personenbezogenen Daten, die aufgrund einer
      Interessenabwägung (Art. 6 Abs. 1 lit. f DSGVO) erfolgt, Widerspruch
      einzulegen. Dies ist insbesondere dann der Fall, wenn die
      Datenverarbeitung nicht zur Erfüllung eines Vertrages erforderlich
      ist. Sofern Sie von Ihrem Widerspruchsrecht Gebrauch machen, bitten
      wir Sie um die Darlegung der Gründe. Wir werden Ihre
      personenbezogenen Daten dann nicht mehr verarbeiten, es sei denn, wir
      können Ihnen gegenüber nachweisen, dass zwingende schutzwürdige
      Gründe an der Datenverarbeitung Ihre Interessen und Rechte
      überwiegen.</p>
    <p>
		<span style="text-decoration: underline;"><strong>Unabhängig
				vom vorstehend Gesagten, haben Sie das jederzeitige Recht, der
				Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung
				und Datenanalyse zu widersprechen.</strong></span>
    </p>
    <p>Ihren Widerspruch richten Sie bitte an die oben angegebene
      Kontaktadresse des Verantwortlichen.</p>
    <span class="sub_headline">Google AdWords</span>
    <p>Wir nutzen auf unserem Internetauftritt "Google AdWords", einen
      Dienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
      94043, USA.</p>
    <p>
      Google AdWords ist ein Dienst zum Ausspielen von Internetwerbung in
      Form von Anzeigen. Die Anzeigen werden als Suchergebnisse in den
      Suchmaschinenergebnissen oder im Google-Werbenetzwerk angezeigt. Über
      Google AdWord legen wir bestimmte Schlüsselwörter (Keywords) fest.
      Unsere Anzeigen werden von Google dann in den Suchmaschinenergebnissen
      ausgespielt, wenn Sie mit der Suchmaschine von Google ein Suchergebnis
      abrufen, welches mit dem Schlüsselwort in Verbindung steht. Im
      Google-Werbenetzwerk erscheinen unsere Anzeigen auf themenrelevanten
      Internetseiten. Sofern Sie auf eine der Anzeigen klicken, werden Sie
      auf die von uns beworbene Internetseite weitergeleitet. Hierbei setzt
      Google AdWords einen Cookie (siehe zum Begriff oben unter der
      Überschrift Cookies). Hierüber kann sowohl Google als auch wir
      nachvollziehen, ob Sie über unsere Anzeige auf unsere Seite gelangt
      sind und ob Sie hier gegebenenfalls weitere Aktionen vorgenommen
      haben, wie beispielsweise etwas gekauft, ein Kontaktformular bedient
      oder uns angerufen haben. Hierzu werden durch das gesetzte Cookie die
      von Ihnen besuchten Seiten einschließlich Ihrer IP-Adresse erhoben
      und auf Google-Server in den USA übertragen. Google wertet diese
      Daten aus, um daraus einen Bericht mit statistischen Aussagen über
      die Anzahl der über die Werbung generierten Besucher und den Erfolg
      der Werbemaßnahme zu fertigen. Das ermöglicht es&nbsp;uns, unsere
      Anzeigen zu optimieren. Weitere übermittelte Informationen werden der
      IP-Adresse durch Google nicht zugeordnet und mit dieser
      verknüpft.&nbsp;Google gibt diese Daten möglicherweise an Dritte
      weiter. Über das Vorbeschriebene hinaus erhalten wir keine
      Informationen/Daten zu den Personen, die auf unsere Anzeigen klicken.
      <strong>Zweck:</strong> Die vorbenannten Interessen stellen den Zweck
      der Nutzung von Google AdWords dar. <strong>Rechtsgrundlage</strong>:
      Die Nutzung stützt sich auf Art. 6 Abs. 1 lit. f DSGVO als
      Rechtsgrundlage. <strong>Verhinderung: </strong>Sie können das Setzen
      der Cookies verhindern, indem Sie dies in Ihrem Browser einstellen.
      Bereits gesetzte Cookies können in Ihrem Browser gelöscht werden.
      Zudem besteht die Möglichkeit der Verwendung von interessenbezogener
      Werbung zu widersprechen, indem Sie folgenden Link aufrufen und die
      entsprechenden Optionen manuell deaktivieren <a
      href="https://www.google.com/ads/preferences" class="externalLink">http://www.google.com/ads/preferences</a>.
      Weiterhin besteht die Möglichkeit die interessenbezogene Werbung zu
      deaktivieren, indem Sie dies unter folgendem Link einstellen <a
      href="http://www.aboutads.info/choices" class="externalLink">http://www.aboutads.info/choices</a>.
    </p>
    <p>
      <strong>Informationen zum Drittanbieter:</strong>
    </p>
    <p>Sitz innerhalb der EU: Google Dublin, Google Ireland Ltd.,
      Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436
      1001.</p>
    <ul>
      <li>Datenschutzerklärung: <a
        href="•Datenschutzerklärung:  http:/www.google.de/intl/de/policies/privacy">http://www.google.de/intl/de/policies/privacy</a>
      </li>
    </ul>
    <span class="sub_headline">Google AdSense</span>
    <p>Wir nutzen auf unserem Internetauftritt "Google AdSense", einen
      Dienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
      94043, USA (nachfolgend bezeichnet als: "Google").</p>
    <p>
      Der Dienst erlaubt uns eigene Werbung auf Drittseiten anzuzeigen.
      Hierbei setzt Google AdSense einen Cookie (siehe zum Begriff oben
      unter der Überschrift Cookies).Durch das gesetzte Cookie wird Ihre
      Herkunft, die Anzahl der getätigten Klicks einschließlich Ihrer
      IP-Adresse erhoben und auf Google-Server in den USA
      übertragen.&nbsp;Auf die Menge oder Qualität der übertragenen Daten
      haben wir keinen Einfluss. Weitere übermittelte Informationen werden
      der IP-Adresse durch Google nur zugeordnet, sofern Sie bei Ihrem
      Besuch unserer Webseite zeitgleich in Ihrem Google Account eingeloggt
      sind. Zudem verwendet Google AdSense sog. Zählpixel; eine
      Miniaturgrafik, die innerhalb von Webseiten eingesetzt wird und
      Informationen sammelt, die es ermöglichen den Besucherverkehr auf den
      Webseiten zu ermitteln. <strong>Zweck:</strong> Die vorbenannten
      Interessen stellen den Zweck der Nutzung von Google AdSense dar. <strong>Rechtsgrundlage:</strong>&nbsp;Rechtsgrundlage
      für die Nutzung ist Art. 6 Abs. 1 lit. f DSGVO. <strong>Verhinderung:</strong>
      Sie können die Speicherung der Cookies verhindern, indem Sie dies in
      Ihrem Browser einstellen. Bereits gesetzte Cookies können in Ihrem
      Browser gelöscht werden. Zudem besteht die Möglichkeit der
      Verwendung von interessenbezogener Werbung zu widersprechen, indem Sie
      folgenden Link aufrufen und die entsprechenden Optionen&nbsp;manuell
      deaktivieren <a href="https://www.google.com/ads/preferences"
                      class="externalLink">http://www.google.com/ads/preferences</a>.
      Weiterhin besteht die Möglichkeit die interessenbezogene Werbung zu
      deaktivieren, indem Sie dies unter folgendem Link einstellen <a
      href="http://www.aboutads.info/" target="_blank" class="externalLink">http://www.aboutads.info/</a>choices.
    </p>
    <p>
      <strong>Informationen zum Drittanbieter:</strong>
    </p>
    <p>Sitz innerhalb der EU: Google Dublin, Google Ireland Ltd.,
      Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436
      1001.</p>
    <ul>
      <li>AdSense: <a
        href="https://www.google.de/intl/de/adsense/start/"
        class="externalLink">https://www.google.de/intl/de/adsense/start/</a></li>
    </ul>
    <span class="sub_headline">Google Remarketing</span>
    <p>Wir nutzen auf unserem Internetauftritt "Google Remarketing",
      einen Dienst der Google Inc., 1600 Amphitheatre Parkway, Mountain
      View, CA 94043, USA.</p>
    <p>
      Google Remarketing ist eine Funktion von Google-AdWords. Google
      Remarketing ermöglicht es uns, Ihnen interessenbezogene Werbeanzeigen
      zu präsentieren. Hierbei setzt Google Remarketing einen Cookie (siehe
      zum Begriff und zur Funktionsweise oben unter der Überschrift
      "Cookies"). Durch das gesetzte Cookie werden die von Ihnen besuchten
      Seiten einschließlich Ihrer IP-Adresse erhoben und auf Google-Server
      in den USA übertragen. Die Daten werden durch Google nicht der
      übertragenen IP-Adresse zugeordnet oder mit dieser verknüpft. Die
      Daten werden von Google in pseudonymisierter Form übertragen, indem
      die Daten keinem Nutzer direkt, sondern dem Cookie und einem dadurch
      erstellen Cookie-Profil zugeordnet wird. Davon ausgenommen sind
      Fälle, in denen der Nutzer einer solchen Pseudonymisierung gegenüber
      Google widerspricht. <strong>Zweck:</strong> Der Einsatz dient der
      Bereitstellung interessenbezogener Werbeanzeigen. <strong>Rechtsgrundlage</strong>:
      Die Rechtsgrundlage für die vorbeschriebene Nutzung der Daten ist
      Art. 6 Abs. 1 lit. f DSGVO. <strong>Verhinderung: </strong>Sie können
      die Speicherung der Cookies verhindern, indem Sie dies in Ihrem
      Browser einstellen, oder unter folgendem Link <a
      href="https://www.google.com/ads/preferences" class="externalLink">http://www.google.com/ads/preferences</a>
      durch Betätigung des Abmelde-Buttons ein Opt-Out-Cookie setzen. Sie
      sind anschließend jedoch möglicherweise nicht mehr in der Lage alle
      Funktionen unserer Webseite in vollem Umfang zu nutzen. Um
      sicherzustellen, dass keine Datenübertragung mehr erfolgt, muss das
      der Opt-Out-Cookie auf jedem der von Ihnen verwendeten Systemen
      installiert werden.
    </p>
    <p>
      <strong>Informationen zum Drittanbieter:</strong>
    </p>
    <p>Sitz innerhalb der EU: Google Dublin, Google Ireland Ltd.,
      Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436
      1001.</p>
    <ul>
      <li>Datenschutzerklärung:&nbsp; <a
        href="https://www.google.de/intl/de/policies/privacy"
        class="externalLink">http://www.google.de/intl/de/policies/privacy</a></li>
    </ul>
    <span class="sub_headline">Facebook Pixel</span>
    <p>Wir nutzen auf unserer Webseite die Funktion Facebook Pixel,
      Custom Audiences und Facebook Conversion der Facebook Inc. 1 Hacker
      Way, Menlo Park, CA 94025, USA.</p>
    <p>
      Facebook Pixel ist ein Analysetool, mit dem wir die Effektivität der
      von uns auf Facebook geschalteten Werbeanzeigen (sog.
      „Facebook-Ads“) auswerten können. Es ermöglicht es uns,
      Zielgruppen für unsere Werbeanzeigen aufzubauen. So ist es uns
      möglich, dass wir unsere Facebook-Ads nur an solche Facebook-Nutzer
      ausspielen, die zuvor eine unserer Internetseiten besucht haben. Zudem
      können wir über Facebook Pixel nachvollziehen, ob Sie, nachdem Sie
      auf eine unserer Facebook-Ads geklickt haben, auf unsere Website
      weitergeleitet wurden. So ist es uns möglich, Ihnen Werbeanzeigen
      auszuspielen, die auf Ihre Interessen abgestimmt ist. Hierzu haben wir
      ein Zählpixel von Facebook auf unserer Webseite implementiert.
      Hierbei handelt es sich um eine kleine Grafik, die eine
      Logdatei-Aufzeichnung und eine Logdateianalyse ermöglicht. Bei Ihrem
      Besuch auf unserer Webseite wird eine Direktverbindung zu den Servern
      von Facebook hergestellt. Nach unserem Kenntnisstand erhält Facebook
      hierbei die Information, welche Seite unserer Internetpräsenz Sie
      besucht haben oder welche unserer Facebook-Ads Sie angeklickt haben.
      Sofern Sie bei Facebook angemeldet sind, kann Facebook Ihren Besuch
      auf unserer Webseite bzw. den Klick auf unsere Facebook-Ads Ihrem
      Facebook-Account zuordnen. Es ist zudem nicht auszuschließen, dass
      Facebook Ihre IP-Adresse und weitere Identifizierungsmerkmale ausliest
      und speichert, selbst wenn Sie nicht bei Facebook angemeldet sind.
      Details zur Funktionsweise des Facebook-Pixels können Sie hier
      nachlesen: https://www.facebook.com/business/help/651294705016616.Wir
      nutzen zudem die Funktion „erweiterter Abgleich“. Dabei werden
      Ihre personenbezogenen Daten in verschlüsselter Form an Facebook
      übertragen.Auch nutzen wir das Verfahren „Custom Audiences from
      File“ . Dabei wird Ihre E-Mailadresse, als Empfänger des
      Newsletters, bei Facebook verschlüsselt hochgeladen. Dies dient uns
      zur Bestimmung des Empfängers unserer Facebook-Werbeanzeigen und um
      zu gewährleisten, dass die Anzeigen nur Interessierten eingeblendet
      werden. Auf die Menge, Qualität oder Speicherdauer der übertragenen
      Daten haben wir keinen Einfluss. Facebook gibt Ihre Daten
      gegebenenfalls an Dritte weiter. Facebook ist Teilnehmer des
      Privacy-Shield Abkommens und zur Datenverarbeitung nach europäischen
      Standards zertifiziert. Zweck: Wir setzen Facebook Pixel ein, um Ihnen
      für Sie interessante Werbung anzuzeigen und unsere Webseite
      attraktiver zu gestalten. Rechtsgrundlage: Die Rechtsgrundlage für
      die vorbeschriebene Nutzung der Daten ist Art. 6 Abs. 1 lit. f DSGVO.
      Verhinderung: Sie können die Facebook Custom Audiences deaktivieren
      in dem Sie sich in Ihren Facebook Account einloggen und dies unter
      folgendem Link einstellen https://www.facebook.com/settings/?tab=ads#_
      Zudem können Sie die Anzeige interessenbezogener Werbung auf
      folgenden Seiten deaktivieren http://www.aboutads.info/choices und
      http://www.youronlinechoices.com/uk/your-ad-choices/. <br>Informationen
      zum Drittanbieter:<br>Sitz innerhalb der EU: Facebook Ireland
      Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.
    </p>
    <ul>
      <li>Datenschutzbestimmungen: https://www.facebook.com/policy.php</li>
      <li>Facebook Pixel:
        https://www.facebook.com/business/help/651294705016616
      </li>
      <li>Erweiterter Abgleich:
        https://www.facebook.com/business/help/611774685654668
      </li>
    </ul>
    <span class="sub_headline">
      <strong>Sicherheitsmaßnahmen</strong>
    </span>
    <p>Wir treffen im Übrigen technische und organisatorische
      Sicherheitsmaßnahmen nach dem Stand der Technik, um die Vorschriften
      der Datenschutzgesetze einzuhalten und Ihre Daten gegen zufällige
      oder vorsätzliche Manipulationen, teilweisen oder vollständigen
      Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu
      schützen.</p>
    <span class="sub_headline">
      <strong>Aktualität und Änderung dieser
        Datenschutzerklärung</strong>
    </span>
    <p>Diese Datenschutzerklärung ist aktuell gültig und hat den
      Stand Mai 2018. Aufgrund geänderter gesetzlicher bzw. behördlicher
      Vorgaben kann es notwendig werden, diese Datenschutzerklärung
      anzupassen.</p>
    <p>
      Diese Datenschutzerklärung wurde mit Hilfe des
      Datenschutzerklärungs-Generator von <a title="SOS Recht"
                                             href="https://datenschutz.sos-recht.de" target="_blank"
                                             class="externalLink">SOS Recht</a> erstellt. SOS Recht ist ein
      Angebot der <a
      title="MMR Müller Müller Rößner Rechtsanwälte Partnerschaft"
      href="http://mueller-roessner.net" target="_blank"
      class="externalLink">MMR Müller Müller Rößner Rechtsanwälte
      Partnerschaft</a> mit Sitz in Berlin.
    </p>
  </div>
</div>
