<div class="header">
  <div class="header_logo">
    <img src="assets/header/logo.png" alt="Tippfreunde Logo" class="header_logo"/>
  </div>
  <div class="header_ajax_loader_container">
    <img id="header_ajax_loader" class="header_ajax_loader" src="assets/header/ajax_loader.gif" alt="Bitte warten..."/>
  </div>
  <div class="actions">
    <span (click)="logout()">Abmelden</span>
  </div>
  <div class="betround" *ngIf="sharedService.currentBetRoundLeague" [routerLink]="['/changebetround']">
    <div class="betround_container" *ngIf="sharedService.currentBetRoundLeague">
      <span class="betround_name">{{sharedService.currentBetRoundLeague?.betRound?.name}}</span>
      <span class="betround_league">{{sharedService.currentBetRoundLeague?.league?.name}} {{sharedService.currentBetRoundLeague?.league?.saison}}</span>
      <span class="betround_image">
      <img class="betround" alt="Tipprundenbild"
           src="https://www.tippfreun.de/LoadBetRoundPhotoServlet?scaleSize=30&amp;betRoundId={{sharedService.currentBetRoundLeague?.betRound?.id}}&amp;randomImageId={{sharedService.randomImageId}}">
      </span>
    </div>
  </div>
</div>

<!-- TRANPARENTER HINTREGRUND FÜR OVERLAYS BZW. FÜR AJAX-LOADER -->
<div id="transparent_overlay" class="transparent_overlay"></div>

<!-- NOTIFICATIONS -->
<simple-notifications [options]="notificationOptions"></simple-notifications>
