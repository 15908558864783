<!-- BEGINN TIPPSTATISTIK OVERLAY -->
<div id="overlay_comment_background" class="dark_overlay" (click)="closeOverlay();"></div>
<div id="overlay_comment" class="overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">KOMMENTAR</span>
    <div class="overlay_ajax_loader">
      <img id="comment_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="closeOverlay();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">KOMMENTAR KOMMENTIEREN
    </span>
  </div>
  <div class="overlay_box_content">

    <div class="new_comment_content">
      <div class="new_comment_text">
      <textarea class="new_comment_textarea" type="text" [(ngModel)]="newcomment"
                [ngModelOptions]="{standalone: true}" required placeholder="Was willst du sagen?"></textarea>
      </div>
      <div class="new_comment_actions">
        <img style="border-radius: 25px; vertical-align: bottom" class="new_comment_user" alt="Nutzerbild"
             src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=50&amp;userId={{sharedService.loggedInUser.id}}&amp;randomUserId=0.6914100716083038">
        <input class="new_comment_button" type="button" value="POSTEN" (click)="saveComment()"/>
      </div>
    </div>

    <div class="comment_box">
      <div class="comment">
                        <span class="user_img"> <img style="border-radius: 25px; vertical-align: bottom" alt="Nutzerbild"
                                                     src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=40&amp;userId={{comment?.userId}}&amp;randomUserId=0.6914100716083038"></span>
        <span class="user_name">{{comment?.user?.formattedName}}<span
          class="date">{{comment?.formattedCreateStamp}}</span></span>
        <span class="comments_count">{{comment?.childCommentCount}} <span
          class="comment_label">Kommentare</span></span>
        <span class="loala_count">{{comment?.fanTribuneLaOlas.length}} <span class="comment_label">LaOlas</span></span>
      </div>
      <div class="comment_text">
        {{comment?.comment}}
      </div>
    </div>

    <div class="child_comment_box" *ngFor="let childcomment of comment?.childrenComments;">
      <div class="comment">
                        <span class="user_img"> <img style="border-radius: 25px; vertical-align: bottom" alt="Nutzerbild"
                                                     src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=40&amp;userId={{childcomment?.userId}}&amp;randomImageId={{sharedService.randomImageId}}"></span>
        <span class="user_name">{{childcomment?.user?.formattedName}}<span
          class="date">{{childcomment?.formattedCreateStamp}}</span></span>
        <a class="menu_link" *ngIf="childcomment.userId == sharedService.loggedInUser.id" (click)="sharedJqueryService.openCloseMenu(childcomment.id);">
          <img class="more_menu" src="assets/matchday/more_menu.png" alt="Menü"/>
          <img class="more_menu" src="assets/matchday/more_menu_hover.png" alt="Menü"/>
        </a>
        <div id="menu_{{childcomment.id}}" class="menu">
          <a class="menu_entry" *ngIf="childcomment.userId == sharedService.loggedInUser.id"
             (click)="deleteComment(childcomment.id)"> <span
            class="menu_entry_icon">
                                                  <img class="menu_entry_icon" src="assets/comment/delete.png" alt="Löschen"/>
                                              </span> <span class="menu_entry_text"> LÖSCHEN </span>
          </a>
        </div>
      </div>
      <div class="comment_text">
        {{childcomment?.comment}}
      </div>
    </div>


  </div>

</div>
<!-- ENDE TIPPSTATISTIK OVERLAY -->
