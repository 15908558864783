<!-- BEGINN KONTAKT OVERLAY -->
<div id="overlay_contact_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_contact" class="overlay_box overlay_box_auto_height">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">KONTAKT</span>
    <div class="overlay_ajax_loader">
      <img id="contact_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">
      Fragen? Ideen? Probleme? Schreib uns...
    </span>
  </div>
  <div class="overlay_box_content">

    <form class="overlay_form" (ngSubmit)="contact(); sharedJqueryService.showAjaxLoader('contact_ajax_loader');">
      <div class="standard_input_text">
        <label class="input_text_label input_text_label_down">E-Mail</label>
        <input type="text" [(ngModel)]="mail"
               [ngModelOptions]="{standalone: true}" required/>
      </div>
      <div class="standard_input_text">
        <label class="input_text_label input_text_label_down">Betreff</label>
        <input type="text" [(ngModel)]="subject"
               [ngModelOptions]="{standalone: true}" required/>
      </div>
      <div class="standard_input_text">
        <label class="input_text_label input_text_label_down">Anfrage</label>
        <textarea [(ngModel)]="text"
                  [ngModelOptions]="{standalone: true}" required></textarea>
      </div>
      <div class="uirepo_checkbox">
        <label>
          <input type="checkbox"
                 [(ngModel)]="dataPrivacy" [ngModelOptions]="{standalone: true}"/>
          <i class="checkbox_helper"></i>
          <span class="checkbox_label agb_label">Ich stimme zu, dass meine Daten zur Bearbeitung meiner Anfrage verarbeitet werden. Weitere Hinweise hierzu finden Sie in unserer  <a
            class="agb_link"
            (click)="sharedJqueryService.openCloseOverlay('overlay_agb', 'show');">Datenschutzerklärung</a></span>
        </label>
      </div>
      <div class="standard_input_button" style="padding-right: 5px;">
        <input type="button" class="reset_button" value="ABBRECHEN"
               (click)="sharedJqueryService.closeAllOverlays();"/>
        <input type="submit" value="SENDEN"/>
      </div>
    </form>

  </div>
</div>
<!-- ENDE KONTAKT OVERLAY -->
