<!-- BEGINN WILLKOMMEN OVERLAY -->
<div id="overlay_welcome_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_welcome" class="overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">WILLKOMMEN</span>
    <div class="overlay_ajax_loader">
      <img id="welcome_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">Wie funktioniert tippfreun.de?</span>
  </div>
  <div class="overlay_box_content">

    <div class="welcome_content">
      <div class="welcome_headline">Hallo {{sharedService.loggedInUser.formattedName}},</div>
      <span class="welcome_text">willkommen bei deiner persönlichen Tipp-Community. Wir freuen uns, dass du dich für tippfreunde entschieden hast und wünschen dir viel Spaß beim Tippen mit deinen Freunden. Nachfolgend erhältst du Informationen wie du schnell mit deinen Freunden tippen kannst.</span>

      <div class="question_box">
        <span class="welcome_question">Was sind Tipprunden?</span>
        <span class="welcome_text">Um auf tippfreunde zu tippen,
        musst Du Mitglied einer Tipprunde sein. Innerhalb einer Tipprunde
        kannst Du dich dann mit Deinen Freunden messen.
        Kommentare, Tipps und das Ranking sind nur innerhalb der Tipprunde sichtbar.
          Jede Tipprunde kann mehrere Wettbewerbe und dazu passende Bonusfragen beinhalten.</span>
      </div>

      <div class="question_box">
        <span class="welcome_question"> Wie werde ich Mitglied einer Tipprunde?</span>
        <span class="welcome_text">Jeder registrierte Benutzer
        kann eine eigene Tipprunden erstellen. <span class="welcome_link"
                                                     (click)="sharedJqueryService.closeAllOverlays(); sharedJqueryService.openCloseOverlay('overlay_newbetround', 'show');">TIPPRUNDE ERSTELLEN</span> Der Eigentümer der
        Tipprunde kann anschließend über einen Link Personen in diese Tipprunde einladen.
        Du kannst auch eine Einladung von einem Freund, der bereits eine Tipprunde erstellt
        hat, erhalten. Du kannst gleichzeitig an mehreren Tipprunden teilnehmen.
<br/>
          <span class="info">ACHTUNG: EINE TIPPRUNDE IST ERST TIPPBAR, WENN DIESE EINEN WETTBEWERB HAT</span>
        </span>
      </div>

      <div class="question_box">
        <span class="welcome_question">Hast du schon einen Einladungslink erhalten?</span>
        <span class="welcome_text"> Herzlichen Glückwunsch. Du wurdest bereits in eine Tipprunde eingeladen. Dann klicke auf den Link, den dir deine
          Freunde gesendet haben und trete deiner ersten Tipprunde bei.</span>
      </div>

      <div class="question_box">
        <span class="welcome_question">Hast du dein Profil schon vervollständigt?</span>
        <span class="welcome_text">Wenn nicht, dann lade doch ein Profilbild hoch damit dich deine Freunde besser erkennen. <span
          class="welcome_link" [routerLink]="['/profile']"
          (click)="sharedJqueryService.closeAllOverlays();">MEIN PROFIL</span></span>
      </div>

    </div>

  </div>
</div>
<!-- ENDE WILLKOMMEN OVERLAY -->
