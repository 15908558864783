<div id="coockieInfo" class="cookies">
  <span class="cookies_info">
    Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können
    und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Nutzung unserer Website
    an unsere Partner für soziale Medien, Werbung und Analysen weiter.
    <span class="cookies_link_container"><a class="cookies_link" (click)="allowCoockies()">ich stimme zu</a></span>
  </span>
</div>

<div class="header">
  <div class="header_logo">
    <img class="logo_header" src="assets/home/logo.png" alt="Tippfreunde Logo"/>
  </div>
  <div class="header_app">
    <a href="https://play.google.com/store/apps/details?id=de.tippfreunde&hl=de" target="_blank">
      <img class="store_img" src="assets/home/play_store.svg" alt="Google Store"/>
    </a>
    <!--
    <a href="https://play.google.com/store/apps/details?id=de.tippfreunde&hl=de" target="_blank">
      <img class="store_img" src="assets/home/app_store.svg" alt="App Store"/>
    </a>
    -->
  </div>
</div>

<div class="teaser">
  <img class="logo_teaser" src="assets/home/logo.png" alt="Tippfreunde Logo"/>

  <div class="teaser_text">{{betRound?.name}}</div>
  <div class="teaser_info_text">Trete der Tipprunde bei und erhalte Einsicht auf das Ranking, auf die Tipps der
    Mitglieder und auf die Kommentare im News-Feed. Als Mitglied kannst du Tipps
    innerhalb dieser Tipprunde abgeben. Tipprunden können jederzeit
    wieder verlassen werden.
  </div>
  <div class="login_box">
    <a class="login_link" (click)="sharedJqueryService.openCloseOverlay('overlay_login', 'show');">Beitreten</a>
  </div>
  <div class="register_info">Noch kein Konto? <a class="register_link" (click)="sharedJqueryService.openCloseOverlay('overlay_register', 'show');">Jetzt Registrieren</a>
  </div>
</div>

<div class="betround_infos">
  <span class="betround_info_headline">Tipprundenbeschreibung</span>
  <span class="betround_info_text" *ngIf="betRound?.description">{{betRound.description}}</span>
  <span class="betround_info_text"
        *ngIf="betRound?.description?.length == 0">Für diese Tipprunde liegt keine Beschreibung vor.</span>


  <span class="betround_info_headline">Mitglieder</span>
  <div class="betround_user" *ngFor="let betrounduser of betRound?.betRoundUsers">
    <img style="border-radius: 25px; vertical-align: bottom; border: 2px solid #fff;" alt="Nutzerbild"
         src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=40&amp;userId={{betrounduser.user.id}}&amp;randomImageId={{sharedService.randomImageId}}">
  </div>
  <span class="betround_info_text" *ngIf="betRound?.betRoundUsers?.length == 0">Diese Tipprunde hat noch keine Mitglieder!</span>
</div>

<div class="footer">
  <div class="footer_container">
    <a class="footer_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_contact', 'show');">Kontakt</a>
    <a class="footer_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_agb', 'show');">AGB</a>
    <a class="footer_entry" (click)="sharedJqueryService.openCloseOverlay('overlay_impressum', 'show');">Impressum</a>
  </div>
</div>

<!-- BEGINN ANMELDEN OVERLAY -->
<div id="overlay_login_background" class="dark_overlay" (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_login" class="overlay_box small_overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">ANMELDEN</span>
    <div class="overlay_ajax_loader">
      <img id="login_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">Bitte melde dich mit deinen Nutzdaten an!</span>
  </div>
  <div class="overlay_box_content">
    <div class="content_container">
      <form class="overlay_form" (ngSubmit)="joinBetRound(); sharedJqueryService.showAjaxLoader('login_ajax_loader');">
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down">E-Mail</label>
          <input id="userId" type="text" [(ngModel)]="loginUserId"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down">Passwort</label>
          <input id="password" type="password" [(ngModel)]="loginPassword"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <a class="forget_password"
           (click)="sharedJqueryService.closeAllOverlays(); sharedJqueryService.openCloseOverlay('overlay_forget_password', 'show');">Passwort
          vergessen?</a>
        <div class="standard_input_button" style="padding-right: 5px;">
          <input type="button" class="reset_button" value="ABBRECHEN"
                 (click)="sharedJqueryService.closeAllOverlays();"/>
          <input type="submit" value="BEITRETEN"/>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- ENDE ANMELDEN OVERLAY -->

<!-- BEGINN PASSWORT VERGESSEN OVERLAY -->
<div id="overlay_forget_password_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_forget_password" class="overlay_box small_overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">PASSWORT VERGESSEN</span>
    <div class="overlay_ajax_loader">
      <img id="forget_pw_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">Bitte gib deine E-Mail Adresse ein!</span>
  </div>
  <div class="overlay_box_content">
    <div class="content_container">

      <form class="overlay_form"
            (ngSubmit)="forgetPassword(); sharedJqueryService.showAjaxLoader('forget_pw_ajax_loader');">
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down">E-Mail</label>
          <input type="text" [(ngModel)]="forgetPasswordUserId"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_button" style="padding-right: 5px;">
          <input type="button" class="reset_button" value="ABBRECHEN"
                 (click)="sharedJqueryService.closeAllOverlays();"/>
          <input type="submit" value="ANFORDERN"/>
        </div>
      </form>

    </div>
  </div>
</div>
<!-- ENDE PASSWORT VERGESSEN OVERLAY -->

<!-- BEGINN REGISTRIEREN OVERLAY -->
<div id="overlay_register_background" class="dark_overlay" (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_register" class="overlay_box small_overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">REGISTRIEREN</span>
    <div class="overlay_ajax_loader">
      <img id="register_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">Lege dein eigenes tippfreunde Konto an!</span>
  </div>
  <div class="overlay_box_content">
    <div class="content_container">

      <form class="overlay_form" (ngSubmit)="register(); sharedJqueryService.showAjaxLoader('register_ajax_loader');">
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down">E-Mail</label>
          <input type="text" [(ngModel)]="registerUserId"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down">Passwort</label>
          <input type="password" [(ngModel)]="registerPassword"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down two_lines">Passwort wiederholen</label>
          <input type="password" [(ngModel)]="registerPasswordRepeat"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down">Vorname</label>
          <input type="text" [(ngModel)]="registerFirstName"
                 [ngModelOptions]="{standalone: true}" required/>
        </div>
        <div class="standard_input_text">
          <label class="input_text_label input_text_label_down">Nachname</label>
          <input type="text" [(ngModel)]="registerLastName" [ngModelOptions]="{standalone: true}" required/>
        </div>

        <div class="uirepo_checkbox">
          <label>
            <input type="checkbox"
                   [(ngModel)]="registerAGB" [ngModelOptions]="{standalone: true}"/>
            <i class="checkbox_helper"></i>
            <span class="checkbox_label agb_label">Ich habe die <a class="agb_link"
                                                                   (click)="sharedJqueryService.openCloseOverlay('overlay_agb', 'show');">Datenschutzerklärung</a>
            und die <a class="agb_link"
                       (click)="sharedJqueryService.openCloseOverlay('overlay_agb', 'show');">Allgemeinen
              Geschäftsbedingungen</a>
            gelesen und bin damit einverstanden. Ich bestätige außerdem, dass ich älter als 16 Jahre bin.</span>
          </label>
        </div>
        <div class="standard_input_button" style="padding-right: 5px;">
          <input type="button" class="reset_button" value="ABBRECHEN"
                 (click)="sharedJqueryService.closeAllOverlays();"/>
          <input type="submit" value="REGISTRIEREN"/>
        </div>
      </form>

    </div>
  </div>
</div>
<!-- ENDE REGISTRIEREN OVERLAY -->

<contact></contact>
<impressum></impressum>
<agb></agb>

<!-- NOTIFICATIONS -->
<simple-notifications [options]="notificationOptions"></simple-notifications>
