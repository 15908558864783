<header></header>

<menu [active]="'editLeagues'"></menu>

<div class="content">
  <div class="content_container" *ngIf="leagues">


    <div class="new_league">
      <div class="new_league_header">
        <span class="new_league_header_content">
        Neue Liga hinzufügen...
      </span>
      </div>
      <div class="new_league_content">
        <form (ngSubmit)="saveLeague(); sharedJqueryService.showAjaxLoader('header_ajax_loader');">
          <div class="standard_input_text">
            <label class="input_text_label input_text_label_down">ExtSystem Ref</label>
            <input type="text" [(ngModel)]="league.extSystemRef"
                   [ngModelOptions]="{standalone: true}" required/>
          </div>
          <div class="standard_input_text">
            <label class="input_text_label input_text_label_down two_lines">ExtSystem Saison</label>
            <input type="text" [(ngModel)]="league.extSystemSaison"
                   [ngModelOptions]="{standalone: true}" required/>
          </div>
          <div class="standard_input_text">
            <label class="input_text_label input_text_label_down">Name</label>
            <input type="text" [(ngModel)]="league.name"
                   [ngModelOptions]="{standalone: true}" required/>
          </div>
          <div class="standard_input_text">
            <label class="input_text_label input_text_label_down">Saison</label>
            <input type="text" [(ngModel)]="league.saison"
                   [ngModelOptions]="{standalone: true}" required/>
          </div>
          <div class="standard_input_text">
            <label class="input_text_label input_text_label_down">Gültig bis...</label>
            <input type="datetime-local" [(ngModel)]="league.validTill"
                   [ngModelOptions]="{standalone: true}" required/>
          </div>
          <div class="uirepo_checkbox">
            <label>
              <input type="checkbox"
                     [(ngModel)]="league.koSystem" [ngModelOptions]="{standalone: true}"/>
              <i class="checkbox_helper"></i>
              <span class="checkbox_label" style="color: #444;">K.O. System</span>
            </label>
          </div>
          <div class="uirepo_checkbox">
            <label>
              <input type="checkbox"
                     [(ngModel)]="league.showTeamLogos" [ngModelOptions]="{standalone: true}"/>
              <i class="checkbox_helper"></i>
              <span class="checkbox_label" style="color: #444;">Team-Logos anzeigen</span>
            </label>
          </div>
          <div class="new_league_actions">
            <input type="submit" value="SPEICHERN"/>
          </div>
        </form>
      </div>
    </div>


    <div class="leagues">
      <div class="leagues_container">
        <div class="league_box">
          <div class="league" *ngFor="let league of leagues; let leagueIndex = index;">
            <span class="first_line" style="border-top-left-radius: 3px; border-bottom-left-radius: 3px;">{{league.extSystemRef}}</span>
            <span class="second_line">{{league.extSystemSaison}}</span>
            <span class="first_line">{{league.name}}</span>
            <span class="second_line">{{league.saison}}</span>
            <span class="first_line">{{league.formattedValidTill}}</span>
            <span class="second_line"><span *ngIf="league.koSystem">K.O. System</span><span *ngIf="!league.koSystem">kein K.O. System</span></span>
            <span class="first_line" style="border-top-right-radius: 3px; border-bottom-right-radius: 3px;"><span
              *ngIf="league.showTeamLogos">Logos an</span><span *ngIf="!league.showTeamLogos">Logos aus</span></span>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
