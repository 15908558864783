<header></header>

<menu [active]="'editResults'"></menu>

<div class="content">
  <div class="content_container" *ngIf="editLeagueResults">

    <div class="league_selection">
      {{editLeagueResult.league.name}} {{editLeagueResult.league.saison}}
      <img src="assets/admin/dropdown_arrow.png" alt="Auswählen...">
      <select class="select_league" [(ngModel)]="currentLeagueIndex"
              (ngModelChange)="changeLeague($event)">
        <option *ngFor="let resultLeague of editLeagueResults; let leagueIndex = index"
                [value]="leagueIndex">
          {{resultLeague.league.name}} {{resultLeague.league.saison}}
        </option>
      </select>
    </div>

    <!-- TODO SLIDEBAR MACHEN (TOUCH) -->
    <div class="matchday_slider" *ngIf="editLeagueResult">
      <span (click)="previousMatchday()" class="matchday_slider_arrow"><img src="assets/matchday/left.png" alt="Pfeil Links"/></span>
      <span class="matchday_slider_label">{{editLeagueResult.currentMatchday}}</span>
      <span (click)="nextMatchday()" class="matchday_slider_arrow"><img src="assets/matchday/right.png" alt="Pfeil rechts"/></span>
    </div>

    <div class="matchday">
      <div class="games_container">
        <div class="game_box">
          <div class="game" *ngFor="let game of games; let gameIndex = index;">
            <span class="time">{{game.formattedPlayTime}}</span>
            <span class="teams"><span class="team">{{game.team1}}</span> <span
              class="team_devider">:</span><span
              class="team">{{game.team2}}</span></span>
            <span class="teamresult">
              <input class="bet" type="text" [(ngModel)]="game.goalsTeam1"
                     (keyup)="saveResult(game)"/>
              <span class="bet_devider">:</span>
              <input class="bet" type="text" [(ngModel)]="game.goalsTeam2"
                     (keyup)="saveResult(game)"/>
            </span>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
