<!-- BEGINN NUTZERTIPPSTATISTIK OVERLAY -->
<div id="overlay_usermatchdaybetstatistik_background" class="dark_overlay"
     (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_usermatchdaybetstatistik" class="overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">TIPPSTATISTIK</span>
    <div class="overlay_ajax_loader">
      <img id="betstatistik_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">
      <img style="border-radius: 25px; vertical-align: bottom" alt="Nutzerbild"
           src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=20&amp;userId={{user?.id}}&amp;randomImageId={{sharedService.randomImageId}}">
      {{user?.formattedName}}
    </span>
  </div>
  <div class="overlay_box_content">

    <div class="matchday">
      <div class="games_container">
        <div class="game_box">
          <div class="game"
               *ngFor="let gameWithUserBet of rankingDetails?.gameUserBetWrapperForMatchDay; let gameWithUserBetIndex = index;">
            <span class="time">{{gameWithUserBet.game.formattedPlayTime}}</span>
            <span class="teams"><span class="team">{{gameWithUserBet.game.team1}}</span> <span
              class="team_devider">:</span><span
              class="team">{{gameWithUserBet.game.team2}}</span></span>
            <span class="teamresult" *ngIf="gameWithUserBet.game.finished">{{gameWithUserBet.game.goalsTeam1}}<span
              class="bet_devider">:</span>{{gameWithUserBet.game.goalsTeam2}}</span>
            <span class="teamresult" *ngIf="gameWithUserBet.game.today"><img src="assets/matchday/time.png" alt="Uhr"/></span>
            <span class="teamresult liveresult" *ngIf="gameWithUserBet.game.live">{{gameWithUserBet.userBet?.goalsTeam1}}<span
              class="bet_devider">:</span>{{gameWithUserBet.userBet?.goalsTeam1}}</span>
            <span class="teamresult"
                  *ngIf="!gameWithUserBet.game.live && !gameWithUserBet.game.today && !gameWithUserBet.game.finished"><img
              src="assets/matchday/date.png" alt="Kalender"/></span>
            <span class="teambet" *ngIf="!gameWithUserBet.game.betable || gameWithUserBet.userBet?.fixedBet">
            {{gameWithUserBet.userBet?.goalsTeam1}}
            <span class="bet_devider">:</span>
            {{gameWithUserBet.userBet?.goalsTeam2}}
          </span>
            <span class="teambet" *ngIf="gameWithUserBet.game.betable && !gameWithUserBet.userBet?.fixedBet">
              <span>{{gameWithUserBet.userBet?.goalsTeam1}}</span>
              <span class="bet_devider">:</span>
              <span>{{gameWithUserBet.userBet?.goalsTeam2}}</span>
            </span>
            <span class="points" *ngIf="gameWithUserBet.userBet?.points != null"><span
              [ngClass]="{'three_points': gameWithUserBet.userBet?.points==3,'two_points': gameWithUserBet.userBet?.points==2, 'one_point': gameWithUserBet.userBet?.points==1, 'zero_points': gameWithUserBet.userBet?.points==0 }">
                {{gameWithUserBet.userBet?.points}} <span
              class="point_label" *ngIf="gameWithUserBet.userBet?.points != 1">Punkte</span><span
              class="point_label" *ngIf="gameWithUserBet.userBet?.points == 1">Punkt</span></span></span>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
<!-- ENDE NUTZERTIPPSTATISTIK OVERLAY -->
