<header></header>

<menu [active]="'statistik'"></menu>

<div class="content">
  <div class="content_container" *ngIf="statistik">
    <div class="statistik_container">
      <div class="statistik">
        <ngx-charts-bar-vertical
          [scheme]="colorScheme"
          [results]="statistikData"
          [xAxis]="showXAxis"
          [yAxis]="showYAxis"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          (select)="onSelect($event)">
        </ngx-charts-bar-vertical>
      </div>
    </div>
  </div>
</div>
