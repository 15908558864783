<header></header>

<menu [active]="'changeBetround'"></menu>

<div class="content">

  <div class="content_container">
    <div class="betroundleagues">
      <div class="betroundleague_container" *ngIf="bettableLeagues?.length > 0">
        <span class="headline">Tippbare Ligen</span>
        <div class="betroundleague_box"
             *ngFor="let betroundleague of bettableLeagues; let betroundleagueIndex = index;">
          <div class="betroundleague" *ngIf="!betroundleague.league.expired"
               (click)="changeBetRoundLeague(betroundleague)">
            <span class="bet_round_img"> <img style="border-radius: 25px; vertical-align: bottom" alt="Tipprundenbild"
                                              src="https://www.tippfreun.de/LoadBetRoundPhotoServlet?scaleSize=40&amp;betRoundId={{betroundleague.betRound?.id}}&amp;randomImageId={{sharedService.randomImageId}}"></span>
            <span class="bet_round_name">{{betroundleague.betRound.name}}<span
              class="League_name">{{betroundleague.league.name}} {{betroundleague.league.saison}}</span></span>
            <span class="change_info">Klicken zum Wechseln</span>
          </div>
        </div>
      </div>
    </div>

    <div class="betroundleagues">
      <div class="betroundleague_container" *ngIf="nonBettableLeagues?.length > 0">
        <span class="headline">Abgeschlossene Ligen</span>
        <div class="betroundleague_box"
             *ngFor="let betroundleague of nonBettableLeagues; let betroundleagueIndex = index;">
          <div class="betroundleague" *ngIf="betroundleague.league.expired"
               (click)="changeBetRoundLeague(betroundleague)">
            <span class="bet_round_img"> <img style="border-radius: 25px; vertical-align: bottom" alt="Tipprundenbild"
                                              src="https://www.tippfreun.de/LoadBetRoundPhotoServlet?scaleSize=40&amp;betRoundId={{betroundleague.betRound?.id}}&amp;randomImageId={{sharedService.randomImageId}}"></span>
            <span class="bet_round_name">{{betroundleague.betRound.name}}<span
              class="League_name">{{betroundleague.league.name}} {{betroundleague.league.saison}}</span></span>
            <span class="change_info">Klicken zum Wechseln</span>
          </div>
        </div>
      </div>
    </div>

    <div class="betroundleagues">
      <div class="betroundleague_container" *ngIf="betRoundLeagues?.length == 0">
        <span class="headline no_content">Du hast noch keine Tipprunden</span>
      </div>
    </div>

  </div>
</div>

<!-- WILLKOMMEN OVERLAY -->
<welcome></welcome>

<!-- DATENSCHUTZERKLÄRUNG OVERLAY -->
<dataPrivacyOverlay></dataPrivacyOverlay>
