<!-- BEGINN TIPPSTATISTIK OVERLAY -->
<div id="overlay_betstatistik_background" class="dark_overlay" (click)="sharedJqueryService.closeAllOverlays()"></div>
<div id="overlay_betstatistik" class="overlay_box">
  <div class="overlay_box_header">
    <span class="overlay_box_header_title">TIPPSTATISTIK</span>
    <div class="overlay_ajax_loader">
      <img id="betstatistik_ajax_loader" class="overlay_ajax_loader_img" src="assets/overlay_ajax_loader.gif"
           alt="Bitte warten..."/>
    </div>
    <a class="link" (click)="sharedJqueryService.closeAllOverlays();">
      <div class="overlay_box_header_close_icon"></div>
    </a>
    <span class="overlay_box_header_subtitle">{{game?.team1}} : {{game?.team2}}</span>
  </div>
  <div class="overlay_box_content">
    <div class="content_container" *ngIf="betStatistik">

      <div class="headline">{{betStatistik?.userBets?.length}} Tipps abgegeben;
        {{betStatistik?.usersWithNoUserBet?.length}}
        ausstehend
      </div>
      <div class="statistik_box">

        <div class="statistik_container">
          <div class="statistik">
            <div class="statistik_content_box statistik_content_box_green">
            <span
              class="statistik_percent">{{betStatistik?.formattedTeam1InPercent}}</span>
              <span class="statistik_text">{{game?.team1}}</span>
            </div>
          </div>
          <div class="statistik">
            <div class="statistik_content_box statistik_content_box_yellow">
            <span
              class="statistik_percent">{{betStatistik?.formattedSemiInPercent}}</span>
              <span class="statistik_text">Unentschieden</span>
            </div>
          </div>
          <div class="statistik">
            <div class="statistik_content_box statistik_content_box_red">
            <span
              class="statistik_percent">{{betStatistik?.formattedTeam2InPercent}}</span>
              <span class="statistik_text">{{game?.team2}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="headline" *ngIf="betStatistik?.groupedUserBetsForTeam1.length > 0">{{game?.team1}}</div>
    <div class="statistik_box" *ngIf="betStatistik?.groupedUserBetsForTeam1.length > 0">
      <div *ngFor="let userBetContainer of betStatistik?.groupedUserBetsForTeam1">

        <div class="result_box">
          <div class="statistik_content_box statistik_content_box_grey">
            <span
              class="result">{{userBetContainer.goalsTeam1}}:{{userBetContainer.goalsTeam2}}
              <span
                class="result_count">wird von {{userBetContainer?.userBets?.length}} Spielern getippt</span>
            </span>
            <span class="statistik_text">
                <span class="user_image" *ngFor="let userBet of userBetContainer.userBets">
                  <img class="user_bet_img" (click)="showToast(userBet.user.formattedName);" alt="Nutzerbild"
                       [ngClass]="{'fixed_user_bet_img': userBet.fixedBet}"
                       src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=40&amp;userId={{userBet.user.id}}&amp;randomImageId={{sharedService.randomImageId}}">
                </span>
              </span>
          </div>
        </div>
      </div>
    </div>

    <div class="headline" *ngIf="betStatistik?.groupedUserBetsForRemi.length > 0">Unentschieden</div>
    <div class="statistik_box" *ngIf="betStatistik?.groupedUserBetsForRemi.length > 0">
      <div *ngFor="let userBetContainer of betStatistik?.groupedUserBetsForRemi">
        <div class="result_box">
          <div class="statistik_content_box statistik_content_box_grey">
            <span
              class="result">{{userBetContainer.goalsTeam1}}:{{userBetContainer.goalsTeam2}}
              <span
                class="result_count">wird von {{userBetContainer?.userBets?.length}} Spielern getippt</span>
            </span>
            <span class="statistik_text">
                <span class="user_image" *ngFor="let userBet of userBetContainer.userBets">
                  <img class="user_bet_img" (click)="showToast(userBet.user.formattedName);" alt="Nutzerbild"
                       [ngClass]="{'fixed_user_bet_img': userBet.fixedBet}"
                       src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=40&amp;userId={{userBet.user.id}}&amp;randomImageId={{sharedService.randomImageId}}">
                </span>
              </span>
          </div>
        </div>
      </div>
    </div>

    <div class="headline" *ngIf="betStatistik?.groupedUserBetsForTeam2.length > 0">{{game?.team2}}</div>
    <div class="statistik_box" *ngIf="betStatistik?.groupedUserBetsForTeam2.length > 0">
      <div *ngFor="let userBetContainer of betStatistik?.groupedUserBetsForTeam2">
        <div class="result_box">
          <div class="statistik_content_box statistik_content_box_grey">
            <span
              class="result">{{userBetContainer.goalsTeam1}}:{{userBetContainer.goalsTeam2}}
              <span
                class="result_count">wird von {{userBetContainer?.userBets?.length}} Spielern getippt</span>
            </span>
            <span class="statistik_text">
                <span class="user_image" *ngFor="let userBet of userBetContainer.userBets">
                  <img class="user_bet_img" (click)="showToast(userBet.user.formattedName);" alt="Nutzerbild"
                       [ngClass]="{'fixed_user_bet_img': userBet.fixedBet}"
                       src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=40&amp;userId={{userBet.user.id}}&amp;randomImageId={{sharedService.randomImageId}}">
                </span>
              </span>
          </div>
        </div>
      </div>
    </div>

    <div class="headline" *ngIf="betStatistik?.usersWithNoUserBet.length > 0">Keinen Tipp abgegeben</div>
    <div class="statistik_box" *ngIf="betStatistik?.usersWithNoUserBet.length > 0">
      <div class="result_box">
        <div class="statistik_content_box statistik_content_box_grey">
          <span class="statistik_text" style="padding-top: 10px;">
            <span class="user_image" *ngFor="let user of betStatistik.usersWithNoUserBet">
            <img class="user_bet_img" (click)="showToast(user.formattedName);" alt="Nutzerbild"
                 src="https://www.tippfreun.de/LoadUserPhotoServlet?scaleSize=40&amp;userId={{user.id}}&amp;randomUserId=0.6914100716083038">
          </span>
        </span>
        </div>
      </div>
    </div>
    <span class="info">Spieler mit orangenem Rahmen haben feste Tipps!</span>
  </div>
</div>
<div class="toast" style="display:none">{{toastUser}}</div>
<!-- ENDE TIPPSTATISTIK OVERLAY -->
